<div class="container-fluid p-0">

    <div class="d-border-bottom">
        <div class="p-3">
            <label class="d-header-label m-0">
                
                {{'ThankyouHead' | propValue | uppercase}}
                
            </label>
        </div>
    </div>

    <div class="p-3">

        <div class="mt-3">
            {{'ThankyouConfMsg' | propValue }}
        </div>

    </div>
</div>