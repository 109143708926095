import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BtnUtilService } from 'src/app/services/btn-util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { MODULE } from 'src/app/constants/application-constants';
import * as moment from 'moment';
import { WorkSearchModel } from 'src/app/model/work-search-model';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.css']
})
export class LanguageDropdownComponent implements OnInit, AfterViewInit {

  username:string;
  details: any;
  @Input("module") module:string;

  btnDesable:boolean;
  workSearchObj:WorkSearchModel;

  languageSelected : string = 'en';
  langList:any = [
      {id: 'en', label: "English"},
      {id: 'es', label: "Español"},
      {id: 'hc', label: "Kreyòl Ayisyen"}
  ];
  
  constructor(private dataService:SharedDataService,
    private rxStompService: RxStompService,
    private btnService:BtnUtilService) { }

  ngAfterViewInit(): void {
    if(!(this.module === MODULE.Start || this.module === MODULE.IncomeDirections)){
      this.btnDesable = false;
    }  
  }

  ngOnInit(): void {
    this.btnService.desable();
    this.btnService.desabled.subscribe(btnDesable => this.btnDesable = btnDesable);
    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.dataService.currentLanguage.subscribe(lang => this.languageSelected = lang)
  }

  onLanguageChange(event:any){
    this.dataService.updateLanguage(event.target.value)
    console.log(event.target.value)
    if(this.module === MODULE.Start){
      this.btnService.desable();
      localStorage.setItem('startPromptInitTime', moment().format())
    }
    else if(this.module === MODULE.IncomeDirections){
      this.btnService.desable();
      localStorage.setItem('incomePromptInitTime', moment().format())
    }
    
    this.dataService.currentWorkSearchObject.subscribe(workSearchObj => this.workSearchObj= workSearchObj)
    this.workSearchObj.contactResult = '';
    this.workSearchObj.otherDetails = '';
    
    this.rxStompService.onSendMessage(this.details,  {}, {}, [], {}, this.module, 'Language', 'Language', event.target.value, this.username, '');
  }

}
