import { Directive, HostListener, Input, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';

@Directive({
  selector: '[maxcharlength]'
})
export class MaxcharlengthDirective  {

  constructor(private el:ElementRef) { }

  @Input() maxcharlength!:number;

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;
    
    if (currentValue.length > this.maxcharlength) {
      inputElement.value = inputElement.value.slice(0, inputElement.selectionStart! - 1) + inputElement.value.slice(inputElement.selectionStart!)
      inputElement.dispatchEvent(new Event('input'));
    }
    
  }

}
