import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-link-expired',
  templateUrl: './link-expired.component.html',
  styleUrls: ['./link-expired.component.css']
})
export class LinkExpiredComponent implements OnInit {

  language: string = 'en';

  constructor(public spinnerService:NgxSpinnerService, private activatedRoute: ActivatedRoute, private dataService:SharedDataService) { }

  ngOnInit(): void {
    this.spinnerService.hide();

    this.activatedRoute.queryParams.subscribe(params => {
      this.language = params['language']
    });

  if (this.language  == undefined || this.language == '')
    this.dataService.updateLanguage('en')
  else {
    this.dataService.updateLanguage(this.language)
  }
  }
}
