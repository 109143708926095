<div class="container-fluid p-0">

    <div class="d-border-bottom">
        <div class="mx-3 my-2 d-flex justify-content-end" >
            <app-language-dropdown [module]="module"></app-language-dropdown>
        </div>
    </div>
    
    <div class="d-border-bottom">
        <div class="p-3" style="position: relative;">
            <label class="d-header-label m-0">
                {{'ClaimQuesHead' | propValue | uppercase}}
            </label>
        </div>
    </div>
    
    <form #claimQuesForm="ngForm" class="p-3" (ngSubmit)="onClaimQuestionsSubmit(claimQuesForm)">

        <div class="mt-3">
            <!-- Were you able to work and available to work each day? -->
            {{'ClaimQue1' | propValue}}
            <div class="mt-2">
                <button-yesno name="availableToWork" [(ngModel)]="claimQuesObj.availableToWork" btnGroupName="availableToWork"></button-yesno>
            </div>
        </div>
        <div class="mt-3">
            <!-- Did you actively seek work? -->
            {{'WorkSearchQue' | propValue }}
            <div class="mt-2">
                <button-yesno name="seekingWork" [(ngModel)]="claimQuesObj.seekingWork" btnGroupName="seekingWork"></button-yesno>
            </div>
        </div>
        
        <!-- <div class="mt-3">
            Please enter date of your work search
            {{'WorkSearchDate' | propValue }}
            <div class="mt-2 col-6">
                <div class="input-group" [class.disableDate]="claimQuesObj.seekingWork == 'N' || claimQuesObj.seekingWork == ''">
                    <input class="form-control" [readonly]="true" placeholder="yyyy-mm-dd" name="workSearchdate" [maxDate]="maxDate" [minDate]="minDate" [(ngModel)]="claimQuesObj.workSearchdate" ngbDatepicker
                        #d="ngbDatepicker" />
                    <button class="btn btn-continue" (click)="d.toggle()" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                        </svg>
                    </button>
                </div>
            </div>
                
            <div class="red-error" *ngIf="emptySeekingWorkDate">
                {{'WorkSearchDate' | propValue }}
            </div>
        </div> -->
        <div class="mt-3">
            <!-- Did you refuse any work or refuse or fail to go to a job interview? -->
            {{'ClaimQue2' | propValue }}
            <div class="mt-2">
                <button-yesno name="refuseFail" [(ngModel)]="claimQuesObj.refuseFail" btnGroupName="refuseFail"></button-yesno>
            </div>
        </div>
        <div class="mt-3">
            <!-- Did you attend school or training? -->
            {{'ClaimQue3' | propValue }}
            <div class="mt-2">
                <button-yesno name="schoolTraining" [(ngModel)]="claimQuesObj.schoolTraining" btnGroupName="schoolTraining"></button-yesno>
            </div>
        </div>

        <div class="mt-3">
            <!-- Since your last claim, have you applied for retirement or pension or has your pension changed?  -->
            {{'ClaimQue4' | propValue }}
            <div class="mt-2">
                <button-yesno name="retirementPension" [(ngModel)]="claimQuesObj.retirementPension" btnGroupName="retirementPension"></button-yesno>
            </div>
        </div>

        <div class="mt-3">
            <!-- Have you returned to work full time? -->
            {{'ReturnWorkQue' | propValue }}
            <div class="mt-2">
                <button-yesno name="returnToWork" (btnChange)="onReturnToWorkBtnChange()" [(ngModel)]="claimQuesObj.returnToWork" btnGroupName="returnToWork"></button-yesno>
            </div>
        </div>

        <div class="mt-3">
            {{'ReturnWorkDate' | propValue }}
            <div class="mt-2 col-6">
                <div class="input-group" [class.disableDate]="claimQuesObj.returnToWork == 'N' || claimQuesObj.returnToWork == ''">
                    <input class="form-control" [readonly]="true" placeholder="yyyy-mm-dd" name="returntoworkdate" [maxDate]="maxDate" [minDate]="minDate" [(ngModel)]="claimQuesObj.returntoworkdate" ngbDatepicker
                        #returntowaorkdate="ngbDatepicker" />
                    <button class="btn btn-continue" (click)="returntowaorkdate.toggle()" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="red-error" *ngIf="emptyReturnToWorkDate">
                {{'ReturnWorkDate' | propValue }}
            </div>
        </div>
        
        <div class="red-error mt-4" *ngIf="ErrorQuestions">
            {{'ErrorQuestions' | propValue }}
        </div>
        <!-- <button-group></button-group> -->
        <div class="my-4 d-flex justify-content-between">
            <button type="submit" class="btn btn-continue">
                {{'btnContinue' | propValue | uppercase}}
            </button>
            <button type="button" class="btn btn-back" (click)="goBackToIncome()">
                {{'btnBack' | propValue | uppercase}}
            </button>
            <button type="button" class="btn btn-cancel" (click)="endCall()">
                {{'btnCancel' | propValue | uppercase}}
            </button>
        </div>
    </form>
</div>