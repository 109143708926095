import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RxStompService } from '../stomp/rx-stomp.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class DialogBoxService {

  modalOptions:NgbModalOptions;

  constructor(
    private modal:NgbModal, 
    private rxStompService: RxStompService,
    private spinnerService:NgxSpinnerService,
    ) {
      this.modalOptions = {
        // size: 'sm'
        // backdrop:'static',
        // backdropClass:'customBackdrop'
      }
     }

  open(content:any, module:string, details:any, username:any){
     this.modal.open(content, this.modalOptions).result.then(
      result => {
        if(result === 'yes'){
          console.log(result)
          this.spinnerService.show();
          if(module == 'SubmitClaim')
             this.rxStompService.onSendMessage(details, {}, {}, [], {},'SubmitClaim','CallEnd', 'CallEnd', 'CallEnd', username, ''); 
          else
             this.rxStompService.onSendMessage(details, {}, {}, [], {}, module, 'TRANSFER', 'TRANSFER', 'TRANSFER', username, '')
        }
      }, 
      reason => {console.log(reason)})

  }
}
