import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  date: any = {};
  saturdayMonth: string;
  saturdayDay: string;
  saturdayYear: string;
  username: string;
  details: any;
  visualIVRData: any;

  constructor(
    private dataService: SharedDataService,
    public spinnerService:NgxSpinnerService,
    private rxStompServive:RxStompService
    ) {
  }

  ngOnInit(): void {
    console.log('Success Initialized')
    this.spinnerService.hide();
    
    this.dataService.currentUIDates.subscribe(date => this.date = date);
    if(this.date){
      this.saturdayMonth = this.date.saturday.split(' ')[0]
      this.saturdayDay = this.date.saturday.split(' ')[1]
      this.saturdayYear = this.date.saturday.split(' ')[2]
    }
    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompServive.deactivate()
    
  }

  close() {
    // this.data.updateLoader(true)
    // this.ChatService.sendData(this.details, 'success', 'CallEnd', 'CallEnd', 'CallEnd', this.username)
  }
}
