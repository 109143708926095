<div>
    <input type="radio" class="btn-check" 
        [name]="btnGroupName" 
        id="{{btnGroupName}}Yes" 
        (change)="onBtnValueChange($event)"
        [(ngModel)]="btnGroupValue" autocomplete="off" value="Y">
    <label class="btn btn-secondary btn-yesno mx-3" for="{{btnGroupName}}Yes">{{'btnYes' | propValue | titlecase }}</label>
    <input type="radio" class="btn-check" 
        [name]="btnGroupName" 
        id="{{btnGroupName}}No"
        (change)="onBtnValueChange($event)"
        [(ngModel)]="btnGroupValue" autocomplete="off" value="N">
    <label class="btn btn-secondary btn-yesno mx-3" for="{{btnGroupName}}No">{{'btnNo' | propValue | titlecase }}</label>
</div>