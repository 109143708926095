import { Directive, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[uszipcode]'
})
export class UszipcodeDirective {

  private previousValue:string = '';
  private previousSelectionStart: number = 0;

  constructor(private hostElement: ElementRef, private ngModel: NgModel) {

  }

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    // console.log(event)
    const element = event.target as HTMLInputElement;
    let key = element.value.charAt(element.selectionStart! - 1 )
    // console.log(key, element.selectionStart)
    // console.log('\'' + element.value + '\'', key)

    // console.log(this.previousSelectionStart, this.previousValue, event.inputType)
    this.previousSelectionStart = element.selectionStart!
    this.previousValue = element.value

    let value = element.value.replace(/\D/g, '');

    if(value.length >= 5){
      value = value.slice(0, 5) + '-' + value.slice(5)
    }
    if(value.charAt(value.length-1) == '-'){
      value = value.replace('-','')
    }
    element.value = value;

    this.ngModel.update.emit(value)
    if(this.previousSelectionStart < 5){
      element.setSelectionRange(this.previousSelectionStart,this.previousSelectionStart)    
    }else
    element.setSelectionRange(this.previousSelectionStart + (value.length - this.previousValue.length),this.previousSelectionStart + (value.length - this.previousValue.length))
    // console.log(this.previousSelectionStart, this.previousValue, event.inputType)

  }

}
