export class ClaimQuestionsModel {
    
    public availableToWork:string = '';
    public seekingWork:string = '';
    public refuseFail:string = '';
    public schoolTraining:string = '';
    public retirementPension:string = '';
    public returnToWork:string = '';
    public returntoworkdate:any = '';
}
