<form #employerDetailForm="ngForm" (ngSubmit)="onEmployerDetailsSubmit(employerDetailForm)">
    <div class="d-border-bottom my-4" *ngIf="employerNum > 1">

    </div>
    <div class="row">
        <div class="mt-3">

            <label class="form-label" for="">{{'EmployerIncome' | propValue | titlecase}}{{employerNum}}</label>
            <i *ngIf="employerNum > 1" class="remove-x bi bi-x-lg me-2 opacity-50 float-end" (click)="close()"></i>
            <div class="input-group">
                <span class="input-group-text" validateDollarsid="basic-addon1">$</span>
                <input type="text" placeholder="" [maxcharlength]="5" class="form-control" name="totalEarning" 
                [(ngModel)]="employerInfoObj.totalEarning" aria-describedby="basic-addon1" autocomplete="nofill"
                (paste)="onPaste($event);" numeric>
            </div>
            <span class="red-error" *ngIf="invalidDollar">
                <small>{{'ErrorDollar' | propValue | titlecase}}</small>
            </span>

        </div>

        <div class="mt-3">

            <input type="text" [maxcharlength]="100" placeholder="{{'EmployerNamePlaceholder' | propValue| titlecase}}" class="form-control" [(ngModel)]="employerInfoObj.employerName" name="employerName"/>
            
            <span class="red-error" *ngIf="emptyName">
                <small>{{'ErrorEmployerName' | propValue | titlecase}}</small>
            </span>

        </div>

        <div class="mt-3">
            
            <input type="text" placeholder="{{'AddressPlaceholder' | propValue| titlecase}}" class="form-control" [(ngModel)]="employerInfoObj.employerAddress" name="employerAddress">
            <span class="red-error" *ngIf="emptyAddress">
                <small>{{'ErrorAddress' | propValue | titlecase}}</small>
            </span>

        </div>

        <div class="mt-3">
            
            <input type="text" [maxcharlength]="50" placeholder="{{'CityPlaceholder' | propValue| titlecase}}" class="form-control"  [(ngModel)]="employerInfoObj.employerCity" name="employerCity">
            <span class="red-error" *ngIf="emptyCity">
                <small>{{'ErrorCity' | propValue | titlecase}}</small>
            </span>

        </div>

        <div class="mt-3 col-6">
            
            <input type="text" [maxcharlength]="50" placeholder="{{'StatePlaceholder' | propValue| titlecase}}" class="form-control" [(ngModel)]="employerInfoObj.employerState" name="employerState">
            <span class="red-error" *ngIf="emptyState">
                <small>{{'ErrorState' | propValue | titlecase}}</small>
            </span>

        </div>
        
        <div class="mt-3 col-6">
            
            <input type="text" [maxcharlength]="10" placeholder="{{'ZipPlaceholder' | propValue| titlecase}}" class="form-control" 
            [(ngModel)]="employerInfoObj.employerZip" autocomplete="nofill" (paste)="onPaste($event);" uszipcode
                name="employerZip" >
            {{event}}
            <span class="red-error" *ngIf="emptyZip || invalidZip">
                <small>{{'ErrorZipCode' | propValue | titlecase}}</small>
            </span>

        </div>
        
    </div>
</form>