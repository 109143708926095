<div class="container-fluid p-0">
    <div class="d-border-bottom">
        <div class="p-3" style="position: relative;">
            <label class="form-label" class="d-header-label">
                <!-- BENEFITS LOGIN -->
                {{'LOGINHead' | propValue | uppercase}}
            </label>
        </div>
    </div>
   
    <div class="p-3">


        <div class="d-grid gap-3 mt-3">
                <img src="https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/signin.svg" 
                    (click)="onVerifyMe()" style="cursor: pointer;"/>    
    
                <button type="button" class="btn btn-back" (click)="onCancel()">
                    {{'btnCancel' | propValue | uppercase}}
                </button>
        </div>

    </div>

</div>