import { Component, Input, OnInit } from '@angular/core';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';

@Component({
  selector: 'button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.css']
})
export class ButtonGroupComponent implements OnInit {

  @Input() next:string;
  @Input() back:string;

  constructor(
    private rxStompService: RxStompService,
  ) { }

  ngOnInit(): void {
  }

  gotoNext() {
    console.log('Go to <' + this.next + '>')
    // this.rxStompService.onSendMessage(this.details, this.moduleName, 'SUCCESS', 'SUCCESS', 'SUCCESS', this.username)
  }

  gotoBack() {
    console.log('Go back to <' + this.back + '>')
    // this.rxStompService.onSendMessage(this.details, this.moduleName, 'BACK', 'BACK', 'BACK', this.username)
  }

  endCall() {
    console.log('End call')
    // this.rxStompService.onSendMessage(this.details, this.moduleName, 'CallEnd', 'CallEnd', 'CallEnd', this.username)
  }
}
