<div class="container-fluid p-0">

    <div class="d-border-bottom">
        <div class="mx-3 my-2 d-flex justify-content-end" >
            <app-language-dropdown [module]="module"></app-language-dropdown>
        </div>
    </div>

    <div class="d-border-bottom">
        <div class="p-3" style="position: relative;">
            <label class="form-label" class="d-header-label m-0">
                <!-- Work Search -->
                {{'WorkSearchHead' | propValue | uppercase}}
            </label>
        </div>
    </div>

    <div class="p-3">
  
        <form #workSearchDetailForm="ngForm" (ngSubmit)="onWorkSearchDetailSubmit(workSearchDetailForm)">

            <div class="row">    
                <div class="mt-3">
                    <label class="form-label" for="">{{'EmployerInfo' | propValue | titlecase}}</label>
                    <input type="text" [maxcharlength]="100" placeholder="{{'EmployerNamePlaceholder' | propValue| titlecase}}" class="form-control" [(ngModel)]="workSearchObj.employerName" name="employerName"/>
                    
                    <span class="red-error" *ngIf="emptyName">
                        <small>{{'ErrorEmployerName' | propValue | titlecase}}</small>
                    </span>
        
                </div>
        
                <div class="mt-3">
                    
                    <input type="text" placeholder="{{'AddressPlaceholder' | propValue| titlecase}}" class="form-control" [(ngModel)]="workSearchObj.employerAddress" name="employerAddress">
                    <span class="red-error" *ngIf="emptyAddress">
                        <small>{{'ErrorAddress' | propValue | titlecase}}</small>
                    </span>
        
                </div>
        
                <div class="mt-3">
                    
                    <input type="text" [maxcharlength]="50" placeholder="{{'CityPlaceholder' | propValue| titlecase}}" class="form-control"  [(ngModel)]="workSearchObj.employerCity" name="employerCity">
                    <span class="red-error" *ngIf="emptyCity">
                        <small>{{'ErrorCity' | propValue | titlecase}}</small>
                    </span>
        
                </div>
        
                <div class="mt-3 col-6">
                    
                    <input type="text" [maxcharlength]="50" placeholder="{{'StatePlaceholder' | propValue| titlecase}}" class="form-control" [(ngModel)]="workSearchObj.employerState" name="employerState">
                    <span class="red-error" *ngIf="emptyState">
                        <small>{{'ErrorState' | propValue | titlecase}}</small>
                    </span>
        
                </div>
                
                <div class="mt-3 col-6">
                    
                    <input type="text" [maxcharlength]="10" placeholder="{{'ZipPlaceholder' | propValue| titlecase}}" class="form-control" 
                    [(ngModel)]="workSearchObj.employerZip" autocomplete="nofill" (paste)="onPaste($event);" uszipcode
                        name="employerZip" >
                    {{event}}
                    <span class="red-error" *ngIf="emptyZip || invalidZip">
                        <small>{{'ErrorZipCode' | propValue | titlecase}}</small>
                    </span>
        
                </div>

                <div class="mt-3">

                    <input type="text" [maxcharlength]="50" placeholder="{{'WebAddressPlaceholder' | propValue| titlecase}}" class="form-control" [(ngModel)]="workSearchObj.webAddress" name="webAddress"/>
                    
                </div>

                <div class="mt-3">
                    <!-- Please enter date of your work search -->
                    {{'WorkSearchDate' | propValue }}
                    <div class="mt-2 col-6">
                        <div class="input-group">
                            <input class="form-control" [readonly]="true" placeholder="yyyy-mm-dd" name="workSearchdate" [maxDate]="maxDate" [minDate]="minDate" [(ngModel)]="workSearchObj.workSearchdate" ngbDatepicker
                                #d="ngbDatepicker" />
                            <button class="btn btn-continue" (click)="d.toggle()" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                        
                    <div class="red-error" *ngIf="emptySeekingWorkDate">
                        {{'WorkSearchDate' | propValue }}
                    </div>
                </div>

                <div class="mt-3">
                    <input type="text" [maxcharlength]="50" placeholder="{{'WorkSearchPlaceholder' | propValue| titlecase}}" class="form-control" [(ngModel)]="workSearchObj.typeOfWorkSought" name="typeOfWorkSought"/>
                    
                    <span class="red-error" *ngIf="emptyTypeOfWorkSearch">
                        <small>{{'ErrorWorkSearch' | propValue | titlecase}}</small>
                    </span>
        
                </div>

                <div class="mt-3">
                    <select class="form-select" [(ngModel)]="workSearchObj.contactResult" name="contactResult" required (change)="onSelect($event)">
                        <option value="" disabled selected hidden>{{'ContactResultPlaceholder' | propValue | titlecase}}</option>
                        <option *ngFor="let op of WSOPTIONS">{{op.key | propValue}}</option>
                    </select>
                    <span class="red-error" *ngIf="emptyContactResult">
                        <small>{{'ErrorContactResult' | propValue | titlecase}}</small>
                    </span>
        
                </div>

                <div class="mt-3">
                    <input type="text" [class.disableField]="workSearchObj.contactResult != 'Other' && workSearchObj.contactResult != 'Otro' && workSearchObj.contactResult != 'Lòt'" [maxcharlength]="100" placeholder="{{'OtherPlaceholder' | propValue| titlecase}}" class="form-control" [(ngModel)]="workSearchObj.otherDetails"  name="otherDetails"/>
                    <span class="red-error" *ngIf="emptyOther">
                        <small>{{'ErrorContactResult' | propValue | titlecase}}</small>
                    </span>
        
                </div>
                                
            </div>
            <!-- <button-group moduleName="work-search"></button-group> -->
            <div class="my-4 d-flex justify-content-between">
                <button type="submit" class="btn btn-continue">
                    {{'btnContinue' | propValue | uppercase}}
                </button>
                <button type="button" class="btn btn-back" (click)="goBackToClaimQuestions()">
                    {{'btnBack' | propValue | uppercase}}
                </button>
                <button type="button" class="btn btn-cancel" (click)="endCall()">
                    {{'btnCancel' | propValue | uppercase}}
                </button>
            </div>
        </form>

    </div>

</div>