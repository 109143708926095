import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployerInfoModel } from 'src/app/model/employer-info-model';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ValidateService } from 'src/app/services/validate.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';

@Component({
  selector: 'employer-info',
  templateUrl: './employer-info.component.html',
  styleUrls: ['./employer-info.component.css']
})
export class EmployerInfoComponent implements OnInit {

   @Input() employerNum:number = 1;
   @Input() employerId:number = 1;

   @Output() closed = new EventEmitter<any>();
   @ViewChild('employerDetailForm') form: NgForm;
   @Output() isFormValid = new EventEmitter<any>();

  employerInfoObj:EmployerInfoModel = new EmployerInfoModel();

  invalidDollar:boolean = false;
  invalidName:boolean = false;
  emptyName:boolean = false;
  invalidAddress:boolean = false;
  emptyAddress:boolean = false;
  invalidCity:boolean = false;
  emptyCity:boolean = false;
  invalidState:boolean = false;
  emptyState:boolean = false;
  invalidZip:boolean = false;
  emptyZip:boolean = false;

  invalidForm:boolean = false;
  event:Event
  constructor(
    public validate:ValidateService
    ) {
      
  }
  
  ngOnInit(): void {

  }

  onPaste(e:any){
    e.preventDefault()

  }

  onEmployerDetailsSubmit(employerDetailForm:any){

    if(this.validate.validateDollar(employerDetailForm.value.totalEarning)){
      this.invalidDollar = false
    } else{
      this.invalidDollar = true
    }

    if(employerDetailForm.value.employerName == '' || employerDetailForm.value.employerName == undefined){
      this.emptyName = true
    } else{
      this.emptyName = false
    }

    if(employerDetailForm.value.employerAddress == '' || employerDetailForm.value.employerAddress == undefined){
      this.emptyAddress = true
    } else{
      this.emptyAddress = false
    }

    if(employerDetailForm.value.employerCity == '' || employerDetailForm.value.employerCity == undefined){
      this.emptyCity = true
    } else{
      this.emptyCity = false
    }

    if(employerDetailForm.value.employerZip == '' || employerDetailForm.value.employerZip == undefined){
      this.emptyZip = true
    } else{
      this.emptyZip = false
    }

    if(employerDetailForm.value.employerZip.length != 5 && employerDetailForm.value.employerZip.length != 10){
      this.invalidZip = true
    } else{
      this.invalidZip = false
    }

    if(employerDetailForm.value.employerState == '' || employerDetailForm.value.employerState == undefined){
      this.emptyState = true
    } else{
      this.emptyState = false
    }

    if(this.invalidDollar || this.emptyName || this.emptyAddress || this.emptyCity || this.emptyZip || this.invalidZip || this.emptyState){
      this.invalidForm = true
    }
    else{
      this.invalidForm = false
    }

  }

  close(){
    this.closed.emit({
      employerId: this.employerId
    })
  }

  triggerSubmit(){
    this.form.ngSubmit.emit()
  }

}
