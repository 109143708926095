import { Component, HostListener, OnInit } from '@angular/core';
import { RxStompService } from './stomp/rx-stomp.service';
import { STOMP } from './constants/application-constants';
import { SharedDataService } from './services/shared-data.service';
import * as moment from 'moment';
import { BtnUtilService } from './services/btn-util.service';
// import { StompService } from './services/stomp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  callDetails:string;
  username:string;
  constructor(
    private rxStompService: RxStompService, 
    private dataService:SharedDataService,
    private btnUtilService:BtnUtilService
    ){

  }

  connectTimeout:any;

  @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event:any) {

      // if(!this.rxStompService.connected()){
      //   this.rxStompService.activate()
      // }
      // console.log('BROWSERCLOSE')
      this.rxStompService.onSendMessage(this.callDetails, {}, {}, [], {}, 'BROWSERCLOSE','BROWSERCLOSE', 'BROWSERCLOSE', 'BROWSERCLOSE', this.username, '')
      
    // event.returnValue = "Are you sure?";
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {

    this.dataService.currentcallDetails.subscribe(details => this.callDetails = details)
    this.dataService.currentUsername.subscribe(username => this.username = username)
    this.checkHiddenDocument();
  }

  checkHiddenDocument() {
    if (document.hidden) {
      this.rxStompService.deactivate()
    } else {
      // alert(this.rxStompService.connected())
      if(!this.rxStompService.connected()){
        this.rxStompService.activate()
        
        let id = setInterval(() => {
          if(this.rxStompService.connected()){
            this.rxStompService.onSendMessage(this.callDetails, {}, {}, [], {}, 'REGISTER','REGISTER', 'REGISTER', 'REGISTER', this.username, '')
            
            if(moment().diff(localStorage.getItem('startPromptInitTime'), 'seconds') > 18){
              this.btnUtilService.enable();
              localStorage.removeItem('startPromptInitTime');
            }
            if(moment().diff(localStorage.getItem('incomePromptInitTime'), 'seconds') > 15){
              this.btnUtilService.enable();
              localStorage.removeItem('incomePromptInitTime');
            }
            clearInterval(id)
          }
        }, 200)
        
      }
  }
  }

}
