import { IFrame, RxStompConfig } from '@stomp/rx-stomp';
import { environment } from 'src/environments/environment';
import { STOMP } from '../constants/application-constants';

export const myRxStompConfig: RxStompConfig = {
  // Which server?
  // brokerURL: BASE_URL + CHAT_BROKER,
  brokerURL: environment.SOCKET_BASE_URL + STOMP.SOCKET_URL + '/websocket', // added suffix /websocket for SockJs
  // brokerURL: 'wss://' + environment.BACKEND_IP + '/' + environment.APP_NAME + STOMP.SOCKET_URL + '/websocket', // added suffix /websocket for SockJs

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: 'guest',
    passcode: 'guest',
  },


  // connectionTimeout: 1000,
  // correlateErrors: logError,  

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 10000, // Typical value 0 - disabled
  heartbeatOutgoing: 30000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 1000,


  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
    console.log(new Date(), msg);
  },

};

function logError(error:IFrame):string{
  alert(error.body)
  return ''
}