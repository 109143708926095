import { Directive, HostListener, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[numeric]'
})
export class NumericDirective {

  private previousValue:string = '';
  private previousSelectionStart: number = 0;
  private timer:any;

  keys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
  keyCode:any;

  constructor(private ngModel: NgModel) {
  }

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    
    const element = event.target as HTMLInputElement;
    // console.log(element.selectionStart)
    this.previousSelectionStart = element.selectionStart!
    this.previousValue = element.value
    // console.log(this.previousSelectionStart, this.previousValue, event.inputType)
    // console.log('\'' + element.value + '\'', key)


    element.value = element.value.replace(/\D/g, '');
    /*
    let value = element.value;
    element.value = ''
    for(let i=0; i < value.length; i++){
      if(this.keys.includes(value[i])){
        element.value = element.value + value[i]
      }
      
    }

    if(element.value == '.'){
      element.value = '0.'
    }
    if(key == '.' && event.inputType != 'deleteContentBackward' && (element.value.slice(0, element.selectionStart! - 1) + element.value.slice(element.selectionStart!)).includes('.')){
      // console.log(42)
      element.value = element.value.slice(0, element.selectionStart! - 1) + element.value.slice(element.selectionStart!)
    }
    else{
      
      if(element.value.charAt(0) == '0' && element.value.charAt(1) != '.' && element.value.length > 1){
        element.value = element.value.slice(1)
      }
    }
    */

    this.ngModel.update.emit(element.value)
    
    element.setSelectionRange(this.previousSelectionStart + (element.value.length - this.previousValue.length),this.previousSelectionStart + (element.value.length - this.previousValue.length))
    // console.log(element.value.length - this.previousValue.length)


  }

}
