// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


//local
//const BACKEND_IP = 'localhost';
//const BACKEND_PORT = '8080';

//staging
 //const BACKEND_IP = 'delaware-vivr-uat.aumtech.com';
 //const BACKEND_PORT = '8082';

//Production
const BACKEND_IP = 'delaware-vivr.aumtech.com';
const BACKEND_PORT = '8082';

const BACKEND_APP_NAME = 'DelawareVIVRApp';

export const environment = {
  production: false,

  CONTEXT_PATH : 'DelawareVIVRUI',
  
  //local
 //  BASE_URL : `http://${BACKEND_IP}:${BACKEND_PORT}/${BACKEND_APP_NAME}`,
 // SOCKET_BASE_URL: `ws://${BACKEND_IP}:${BACKEND_PORT}/${BACKEND_APP_NAME}`

  //staging
  // BASE_URL : `https://${BACKEND_IP}/${BACKEND_APP_NAME}`,
  // SOCKET_BASE_URL: `wss://${BACKEND_IP}/${BACKEND_APP_NAME}`,

  //Production
   BASE_URL : `https://${BACKEND_IP}/${BACKEND_APP_NAME}`,
   SOCKET_BASE_URL: `wss://${BACKEND_IP}/${BACKEND_APP_NAME}`,

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
