export const ApplicationStringsEnglish:any = { 

    //buttons
    btnContinue:'Continue',
    btnBack:'Back',
    btnCancel:'Cancel',
    btnYes:'Yes',
    btnNo:'No',
    btnAgree:'I Agree',
    btnSubmit:'Submit',
    btnClose:'Close',
    btnAddEmployer:'Add Employer',

    //login page
    LOGINHead:'BENEFITS LOGIN',
    AuthError: 'Invalid email or password',

    //start page
    StartHead:'START',
    StartParaText1:'Please answer the following questions as they apply to the calendar week beginning Sunday, ',
    StartParaText2:' and ending this past Saturday, ',
    StartParaText3:` You must answer the questions truthfully; any attempt to obtain benefits by entering false information could result in a loss of benefits and legal action. 
                    If any question is left unanswered or if this line is disconnected for any reason before completion, your payment for this week will not be processed and
                    you will need to call in again.`,
    
    //income page
    IncomeHead:'ENTER YOUR INCOME',
    IncomeParaText1:'You must enter any earning you had or any income you received for the calendar week beginning Sunday,',
    IncomeParaText2:' and ending this past Saturday, ',
    IncomeParaText3:' If you have no income of a particular type to report, please enter 0 in the appropriate box. Gross wages must be reported when earned, not paid. Please round amounts to the nearest dollar.',
    
    //income/enter-income page
    TotalEarningsLabel:'Total Earnings',
    RetirementIncomeLabel:'Retirement Income',
    SeveranceIncomeLabel:'Severance Income',
    OtherIncomeLabel:'Other Income',

    //income/employer-income page
    EmployerIncome:'Employer Income #',
    ErrorEmployerName:'Please enter the employer\'s name',
    ErrorAddress:'Please enter the employer\'s address',
    ErrorCity:'Please enter the employer\'s city name.',
    ErrorZipCode:'Please enter the employer\'s zip code',
    ErrorState:'Please enter the employer\'s state',
    ErrorDollar:'Please round all amounts up to the next dollar. If you had no earnings, please enter zero dollars.',

    //common form
    EmployerNamePlaceholder:'Employer Name',
    EmployerNameErrorMsg:'',
    AddressPlaceholder:'Address',
    AddressErrorMsg:'',
    CityPlaceholder:'City',
    CityErrorMsg:'',
    StatePlaceholder:'State',
    StateErrorMsg:'',
    ZipPlaceholder:'Zip',
    ZipErrorMsg:'',
    WebAddressPlaceholder:'Web Address',
    WebAddressErrorMsg:'',
    WorkSearchPlaceholder:'Type of Work Sought',
    WorkSearchErrorMsg:'',
    ContactResultPlaceholder:'Contact Result',
    ContactResultErrorMsg:'',
    OtherPlaceholder:'Other (Specify below)',
    OtherResultErrorMsg:'',

    //work-search page
    WorkSearchHead:'WORK SEARCH',
    EmployerInfo:'Employer Information',
    WorkSearchDate:'Please enter date of your work search',
    ErrorWorkSearch:'Please enter the type of work search',
    ErrorContactResult:'Please enter the contact result of your work search',

    ContactResult: "Contact Result",
    FiledApplication:"Filed Application",
    FiledResume:"Filed Resume",
    ISAI:"Interview Scheduled/Attended Interview", 
    ENHATT:"Employer Not Hiring At This Time", 
    Hired:"Hired", 
    Other:"Other",

    //claim-questions page
    ClaimQuesHead:'CLAIM QUESTIONS',
    ClaimQue1:'Were you able to work and available to work each day?',
    WorkSearchQue:'Did you actively seek work?',
    ClaimQue2:'Did you refuse any work or refuse or fail to go to a job interview?',
    ClaimQue3:'Did you attend school or training?',
    ClaimQue4:'Since your last claim, have you applied for retirement or pension or has your pension changed?',
    ReturnWorkQue:'Have you returned to work full time?',
    ReturnWorkDate:'Please enter the date you returned to work',

    ErrorQuestions:'Please answer all of the claim questions.',

    //submit-claim page
    SubmitClaimHead:'SUBMIT CLAIM',
    SubmitClaimPara1:`I hereby claim unemployment insurance benefits against the state of Delaware via the internet. I know 
                    that the law prescribes penalties for false statements made in connection with this claim.`,
    SubmitClaimPara2:`I certify under penalty of perjury that the statements made in connection with this claim are true to
                    the best of my knowledge and belief. In accordance with the applicable provisions of the privacy act of
                    1974 (P.L.93-579), I authorize my former employer(s) to release all information requested in connection
                    with my claim for unemployment insurance compensation. I am furnishing my social security number as
                    required by the deficit reduction act (DEFRA)(P.L.98-396) as a condition of eligibility for benefits. I
                    understand that information regarding my claim may be furnished to requesting agencies defined in DEFRA
                    for the purposes of income and eligibility verification.`,
    SubmitClaimPara3:`I understand that I am required to report any and all gross wages or income from any source every week
                    that I claim unemployment benefits, even if such wages or income are less than the 50.0% earning
                    allowance and that a failure to report any wages or income received during any week can result in a
                    disqualification from benefits for one year and a fraud disqualification and the establishment of an
                    overpayment.`,
    
    ErrorIAgree:'Please certify that you have read each statement by pressing I agree.',
    
    //success page
    SuccessHead:'SUCCESS',
    SuccessThankuMsg:'Thank you for your certification for the week ending ',
    SuccessConfMsg:'Your claim has been recorded.  If there is a problem you will be contacted by your local unemployment office.',

    //cancel page
    CancelClaimHead:'DELAWARE WEEKLY CLAIMS',
    // CancelClaimMsg:'You have elected not to file for weekly benefits, for week ending.',
    CancelClaimMsg:'Session Ended. Please continue using your touch-tone keypad.',

    ThankyouHead:'THANK YOU',
    ThankyouConfMsg:`Thank you for calling State Of Delaware, Department Of Labor, Division Of Unemployment Insurance.`,
    GoodBye:'Good Bye',

    January:'January',
	February:'February',
	March:'March',
	April:'April',
	May:'May',
	June:'June',
	July:'July',
	August:'August',
	September:'September',
	October:'October',
	November:'November',
	December:'December',

    confirmHead:'Confirmation!',
    confirmMsg:'Are you sure you want to cancel?',
    linkExpiredMsg:'The link has expired. Please call ',
    linkExpiredMsgOr:'or '
}

export const ApplicationStringsSpanish:any = { 

    //buttons
    btnContinue:'CONTINUAR',
    btnBack:'RETROCEDER',
    btnCancel:'CANCELAR',
    btnYes:'Sí',
    btnNo:'No',
    btnAgree:'Acepto',
    btnSubmit:'SOMETER',
    btnClose:'Cerrar',
    btnAddEmployer:'agregar empleador',

    //login page
    LOGINHead:'Iniciar sesión de Beneficios',

    //start page
    StartHead:'Comenzar',
    StartParaText1:'Por favor, responda las siguientes preguntas según corresponda a la semana calendario que comienza el domingo,',
    StartParaText2:' y que finaliza el sábado pasado, ',
    StartParaText3:` debe responder las preguntas con sinceridad; cualquier intento de obtener beneficios al proporcionar información falsa podría resultar en la pérdida de beneficios y acciones legales. Si alguna pregunta queda sin responder o si esta llamada se desconecta por cualquier motivo antes de completarla, el pago correspondiente a esta semana no será procesado y deberá llamar nuevamente.`,

    //income page
    IncomeHead:'Ingrese sus ingresos',
    IncomeParaText1:'Debe ingresar cualquier ingreso que haya tenido o cualquier ingreso que haya recibido para la semana calendario que comienza el domingo,',
    IncomeParaText2:' y que finaliza el sábado pasado, ',
    IncomeParaText3:' si no tiene ingresos de un tipo particular para informar, por favor ingrese 0 en el cuadro correspondiente. Los salarios brutos deben ser reportados cuando se generan, no cuando son pagados. Por favor, redondee las cantidades al dólar más cercano.',
    
    //income/enter-income page
    TotalEarningsLabel:'Total de Ingresos',
    RetirementIncomeLabel:'Ingresos de Jubilación',
    SeveranceIncomeLabel:'ingresos por despido',
    OtherIncomeLabel:'Otros Ingresos',

    //income/employer-income page
    EmployerIncome:'Ingresos del Empleador #',
    ErrorEmployerName:'Por favor, ingrese el nombre del empleador.',
    ErrorAddress:'Por favor, ingrese la dirección del empleador.',
    ErrorCity:'Por favor, ingrese el nombre de la ciudad del empleador.',
    ErrorZipCode:'Por favor, ingrese el código postal del empleador.',
    ErrorState:'Por favor, ingrese el estado del empleador.',
    ErrorDollar:'Por favor, redondee todas las cantidades hacia arriba al próximo dólar. Si no tuvo ingresos, por favor ingrese cero dólares.',

    //common form
    EmployerNamePlaceholder:'Nombre del Empleador',
    EmployerNameErrorMsg:'',
    AddressPlaceholder:'DIRECCIÓN',
    AddressErrorMsg:'',
    CityPlaceholder:'ciudad',
    CityErrorMsg:'',
    StatePlaceholder:'estado',
    StateErrorMsg:'',
    ZipPlaceholder:'Código Postal',
    ZipErrorMsg:'',
    WebAddressPlaceholder:'Sitio web',
    WebAddressErrorMsg:'',
    WorkSearchPlaceholder:'tipo de búsqueda de empleo',
    WorkSearchErrorMsg:'',
    ContactResultPlaceholder:'Resultado del contacto',
    ContactResultErrorMsg:'',
    OtherPlaceholder:'Otro (Especificar abajo)',
    OtherResultErrorMsg:'',

    //work-search page
    WorkSearchHead:'BÚSQUEDA DE EMPLEO',
    EmployerInfo:'informacion del empleador',
    WorkSearchDate:'Por favor, ingrese la fecha de su búsqueda de empleo',
    ErrorWorkSearch:'Por favor, ingrese el tipo de búsqueda de empleo',
    ErrorContactResult:'Por favor, ingrese el resultado de contacto de su búsqueda de empleo',

    ContactResult: "Resultado del contacto",
    FiledApplication: "Solicitud presentada",
    FiledResume: "Currículum presentado",
    ISAI: "Entrevista programada", 
    ENHATT: "Empleador no está contratando en este momento",
    Hired: "Contratado",
    Other: "Otro",

    //claim-questions page
    ClaimQuesHead:'PREGUNTAS DE RECLAMOS',
    ClaimQue1:'¿Pudo trabajar y estuvo disponible para trabajar todos los días?',
    WorkSearchQue:'¿Buscó activamente trabajo?',
    ClaimQue2:'¿Rechazó algún trabajo o no fue a una entrevista de trabajo?',
    ClaimQue3:'¿Asistió a la escuela o a una capacitación?',
    ClaimQue4:'Desde su último reclamo, ¿ha solicitado jubilación o pensión o ha cambiado su pensión?',
    ReturnWorkQue:'¿Regresaste al trabajo la semana pasada?',
    ReturnWorkDate:'Por favor, ingrese la fecha en que regresó al trabajo.',

    ErrorQuestions:'Por favor, responda todas las preguntas del reclamo.',

    //submit-claim page
    SubmitClaimHead:'presentar reclamo',
    SubmitClaimPara1:`Por la presente reclamo los beneficios del seguro de desempleo contra el estado de Delaware a través de Internet. 
                    Sé que la ley prescribe sanciones por declaraciones falsas hechas en relación con este reclamo.`,
    SubmitClaimPara2:`Certifico bajo pena de perjurio que las declaraciones hechas en relación con este reclamo son verdaderas según mi leal saber y entender. 
                    De acuerdo con las disposiciones aplicables de la Ley de Privacidad de 1974 (P.L.93-579), autorizo a mis antiguos empleadores a proporcionar 
                    toda la información solicitada en relación con mi reclamo para la compensación del seguro de desempleo. Estoy proporcionando mi número de 
                    seguro social según lo requiere la Ley de Reducción del Déficit (DEFRA) (P.L.98-396) como condición de elegibilidad para los beneficios. 
                    Entiendo que la información sobre mi reclamo puede ser proporcionada a las agencias solicitantes definidas en DEFRA con fines de verificación de ingresos y elegibilidad.`,
    SubmitClaimPara3:`Entiendo que estoy obligado/a a informar todos y cada uno de los salarios brutos o ingresos de cualquier fuente cada semana que solicite 
                    beneficios por desempleo, incluso si tales salarios o ingresos son inferiores al 50.0% del monto permitido, y que no informar cualquier 
                    salario o ingreso recibido durante cualquier semana puede resultar en una descalificación de beneficios por un año y una descalificación por fraude y el establecimiento de un pago en exceso.`,
    
    ErrorIAgree:'Por favor, certifique que ha leído cada declaración presionando "Acepto".',
    
    //success page
    SuccessHead:'éxito',
    SuccessThankuMsg:'Gracias por su certificación para la semana que finaliza el ',
    SuccessConfMsg:'Su reclamo ha sido registrado. Si hay algún problema, será contactado por su oficina local de desempleo.',

    //cancel page
    CancelClaimHead:'RECLAMOS SEMANALES DELAWARE',
    // CancelClaimMsg:'Usted ha decidido no presentar una solicitud de beneficios semanales para la semana que finaliza el.',
    CancelClaimMsg: 'Sesión finalizada. Continúe utilizando su teclado de tonos.',
    
    ThankyouHead:'GRACIAS',
    ThankyouConfMsg:`Gracias por llamar a la línea directa de información de la División de Seguro de Desempleo de Delaware.`,
    GoodBye:'Adiós',


    January:'Enero',
    February:'Febrero',
    March:'Marzo',
    April:'Abril',
    May:'Mayo',
    June:'Junio',
    July:'Julio',
    August:'Agosto',
    September:'Septiembre',
    October:'octubre',
    November:'noviembre', 
    December:'diciembre',
    Sunday:'Sábado',
    Saturday:'Domingo',

    confirmHead:'¡Confirmación!',
    confirmMsg:'¿Estás seguro de que quieres cancelar?',
    linkExpiredMsg:'El enlace ha caducado. Por favor, llame al ',
    linkExpiredMsgOr:' o al '
    
}

export const ApplicationStringsHaitianCreole:any = { 

    //buttons
    btnContinue:'Kontinye',
    btnBack:'Retounen',
    btnCancel:'Anile',
    btnYes:'wi',
    btnNo:'non',
    btnAgree:'mwen dakò',
    btnSubmit:'SOUMET',
    btnClose:'FERM',
    btnAddEmployer:'ajoute patwon',

    //login page
    LOGINHead:'Avantaj Login',

    //start page
    StartHead:'Kòmanse',
    StartParaText1:'Tanpri reponn kesyon sa yo jan yo aplike pou semèn kalandriye a kòmanse dimanch,',
    StartParaText2:' epi fini Samdi ki sot pase a, ',
    StartParaText3:` Ou dwe reponn kesyon yo verite; nenpòt ki tantativ pou jwenn benefis lè w antre fo enfòmasyon sa kapab lakòz yon pèt benefis ak aksyon legal. Si nenpòt kesyon pa reponn oswa si liy sa a dekonekte pou nenpòt rezon anvan yo fini, yo p ap trete peman ou pou semèn sa a epi w ap bezwen rele ankò.`,

    //income page
    IncomeHead:'Antre revni ou',
    IncomeParaText1:'Ou dwe antre nenpòt salè ou te genyen oswa nenpòt revni ou te resevwa pou semèn kalandriye a kòmanse Dimanch,',
    IncomeParaText2:' epi fini Samdi ki sot pase a, ',
    IncomeParaText3:' Si ou pa gen okenn revni nan yon kalite patikilye pou rapòte, tanpri antre 0 nan kare ki apwopriye a. Se salè brit la nou dwe rapòte, se pa sa yo peye a. Tanpri awondi montan yo jiska dola ki pi pre a.',
    
    //income/enter-income page
    TotalEarningsLabel:'Salè total',
    RetirementIncomeLabel:'salè pou pran retrèt',
    SeveranceIncomeLabel:'revni indemnité',
    OtherIncomeLabel:'lòt revni',

    //income/employer-income page
    EmployerIncome:'revni patwon an #',
    ErrorEmployerName:'Tanpri antre non patwon an',
    ErrorAddress:'Tanpri antre adrès patwon an',
    ErrorCity:'Tanpri antre non vil patwon an ye a',
    ErrorZipCode:'Tanpri antre kòd postal patwon an',
    ErrorState:'Tanpri antre Eta patwon an ye a',
    ErrorDollar:'Tanpri awondi tout montan yo jiska pwochen dola a. Si ou pa te gen okenn salè, tanpri antre $0',

    //common form
    EmployerNamePlaceholder:'non patwon an',
    EmployerNameErrorMsg:'',
    AddressPlaceholder:'adrès',
    AddressErrorMsg:'',
    CityPlaceholder:'vil la',
    CityErrorMsg:'',
    StatePlaceholder:'eta',
    StateErrorMsg:'',
    ZipPlaceholder:'postal',
    ZipErrorMsg:'',
    WebAddressPlaceholder:'Adrès Elektwonik',
    WebAddressErrorMsg:'',
    WorkSearchPlaceholder:'Tip Travay ou te Chèche a',
    WorkSearchErrorMsg:'',
    ContactResultPlaceholder:'Rezilta Kontak',
    ContactResultErrorMsg:'',
    OtherPlaceholder:'Lòt (Spesifye li anba)',
    OtherResultErrorMsg:'',

    //work-search page
    WorkSearchHead:'RECHÈCH TRAVAY',
    EmployerInfo:'Enfòmasyon sou anplwaye',
    WorkSearchDate:'Tanpri antre dat rechèch travay ou a',
    ErrorWorkSearch:'Tanpri antre tip travay ke w’ap chèche a',
    ErrorContactResult:'Tanpri antre rezilta rechèch travay ou a',

    ContactResult: "Rezilta Kontak",
    FiledApplication:"Depoze Aplikasyon",
    FiledResume:"Depoze CV",
    ISAI:"Planifye Entèvyou/Pase Entèvyou", 
    ENHATT:"Anplwaye pa’p Anboche Pou Moman", 
    Hired:"Anboche", 
    Other:"Lòt",

    //claim-questions page
    ClaimQuesHead:'REKLAM KESYON',
    ClaimQue1:'Èske ou te kapab travay ak disponib pou travay chak jou?',
    WorkSearchQue:'Èske w te chèche travay aktivman?',
    ClaimQue2:'Èske ou te refize nenpòt travay oswa ou pa ale pou yon entèvyou travay?',
    ClaimQue3:'Èske w te ale lekòl oswa fòmasyon?',
    ClaimQue4:'Depi dènye reklamasyon w lan, èske w te aplike pou retrèt oswa pansyon oswa èske pansyon w te chanje?',
    ReturnWorkQue:'Èske w te retounen travay semèn pase a?',
    ReturnWorkDate:'Tanpri antre dat ou te retounen nan travay la.',

    ErrorQuestions:'Tanpri reponn tout kesyon reklamasyon yo.',

    //submit-claim page
    SubmitClaimHead:'soumèt reklamasyon',
    SubmitClaimPara1:`Mwen reklame benefis asirans chomaj nan men Eta Delaware pa mwayen entènèt. Mwen konnen ke lalwa preskri penalite pou fo deklarasyon ki fèt an rapò ak reklamasyon sa a.`,
    SubmitClaimPara2:`Mwen sètifye, sou penalite pou fo temwayaj, ke deklarasyon ki fèt anrapò ak reklamasyon sa a se laverite selon tout konesans ak kwayans mwen. Dapre dispozisyon ki aplikab yo nan lwa 1974 sou vi prive (PL93-579), mwen otorize ansyen patwon mwen (yo) pou yo divilge tout enfòmasyon yo mande anrapò ak reklamasyon mwen an pou konpansasyon asirans chomaj. Mwen bay nimewo sekirite sosyal mwen an jan lwa sou rediksyon defisi (DEFRA)(PL98-396) egzije sa kòm yon kondisyon pou m kalifye pou benefis yo. Mwen konprann ke enfòmasyon konsènan reklamasyon mwen an ka bay ajans ki fè demann ke DEFRA defini pou rezon verifikasyon revni ak kalifikasyon.`,
    SubmitClaimPara3:`Mwen konprann ke mwen oblije rapòte nenpòt e tout salè brit oswa revni ki soti nan nenpòt sous chak semèn ke mwen reklame benefis chomaj yo, menm si salè oswa revni sa yo pi piti pase 50.0% alokasyon mwen an e si mwen pa rapòte nenpòt salè oswa revni mwen resevwa pandan nenpòt semèn, sa ka lakòz yon diskalifikasyon nan benefis la pou yon ane ak yon diskalifikasyon pou fwod ak pou etablisman yon peman anplis.`,
    
    ErrorIAgree:'Tanpri sètifye ke ou te li chak deklarasyon lè w peze mwen dakò.',
    
    //success page
    SuccessHead:'siksè',
    SuccessThankuMsg:'Mèsi pou sètifikasyon ou pou semèn nan fini',
    SuccessConfMsg:'Yo anrejistre reklamasyon w lan. Si gen yon pwoblèm, biwo chomaj lokal ou a pral kontakte w.',

    //cancel page
    CancelClaimHead:'REKLAMASYON SEMANDEN DELAWARE',
    CancelClaimMsg:'Sesyon fini. Tanpri kontinye itilize klavye a touch ou a.',

    ThankyouHead:'MÈSI',
    ThankyouConfMsg:`Mèsi paske ou te rele State Of Delaware, Department Of Labor, Division Of Unemployment Insurance.`,
    GoodBye:'orevwa',

    January:'Janvye',
    February:'Fevriye',
    March:'Mas',
    April:'Avril',
    May:'Me',
    June:'Jen',
    July:'Jiyè',
    August:'Out',
    September:'Septanm',
    October:'Oktòb',
    November:'Novanm',
    December:'Desanm',
    Sunday:'Samdi',
    Saturday:'Dimanch',

    confirmHead:'Konfimasyon!',
    confirmMsg:'Èske ou sèten ke ou vle anile?',
    linkExpiredMsg:'Lyen an ekspire. Tanpri rele ',
    linkExpiredMsgOr:'oubyen '
    
}