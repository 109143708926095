import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { APP, MODULE, ROUTE, STOMP } from 'src/app/constants/application-constants';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { HttpService } from 'src/app/services/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { BtnUtilService } from 'src/app/services/btn-util.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  
  username:string;
  language: string = 'en'

  cdrKey: string;
  callDetails: any;
  visualIVRData: any;

  constructor(
    private httpService: HttpService, 
    private dataService:SharedDataService,
    // private rxStompService: StompService,
    private rxStompService: RxStompService,
    private activatedRoute: ActivatedRoute, 
    private router:Router,
    private btnUtilService: BtnUtilService,
    public spinnerService:NgxSpinnerService
    ) { }

  ngOnInit(): void {

    // this.rxStompService.connect();
    this.spinnerService.show();
    this.activatedRoute.queryParams.subscribe(params => {
      this.username = params['username']
      this.language = params['language']
    });

    if (this.username == undefined || this.username == '') {
      this.username = 'linsys4d-PORT2-0225201918411498--html';
		  this.cdrKey = this.username.split("--")[0];
    } else {
      this.cdrKey = this.username.split('--')[0];
    }

    if (this.language  == undefined || this.language == '')
      this.dataService.updateLanguage('en')
    else {
      this.dataService.updateLanguage(this.language)
    }

    this.dataService.currentLanguage.subscribe(language => console.log(language))

    this.callDetails = {
      'cdrKey': this.cdrKey,
      'ani': '',
      'dnis': '',
      'portNum': ''
    }
    this.dataService.updateCallDetails(this.callDetails);
    this.dataService.updateUsername(this.username);

    this.rxStompService.watch(STOMP.CHAT_TOPIC).subscribe((message) => {
      console.log('Module name received as [' + JSON.parse(message.body).module + "]");	
      this.visualIVRData = JSON.parse(message.body);
      if(this.visualIVRData.response === 'PromptEnd'){
        this.btnUtilService.enable();
      }
      if(this.visualIVRData.module === MODULE.Login) {
        this.router.navigateByUrl(ROUTE.LoginComponent);
      } else if(this.visualIVRData.module === MODULE.Start && this.visualIVRData.activity != 'PromptEnd') {
        const uiDates = JSON.parse(this.visualIVRData.response)
        this.dataService.updateUIDates(uiDates)
        this.router.navigateByUrl(ROUTE.StartComponent);
      } else if(this.visualIVRData.module === MODULE.IncomeDirections) {
        this.router.navigateByUrl(ROUTE.IncomeComponent);
      } else if(this.visualIVRData.module === MODULE.EmployerInfo) {
        this.router.navigateByUrl(ROUTE.EmployerInfoComponent);
      } else if(this.visualIVRData.module === MODULE.EnterIncome) {
        this.router.navigateByUrl(ROUTE.EnterIncomeComponent);
      } else if(this.visualIVRData.module === MODULE.ClaimQuestions) {
        this.router.navigateByUrl(ROUTE.ClaimQuestionsComponent);
      } else if(this.visualIVRData.module === MODULE.WorkSearch) {
        this.router.navigateByUrl(ROUTE.WorkSearchComponent);
      } else if(this.visualIVRData.module === MODULE.SubmitClaim) {
        this.router.navigateByUrl(ROUTE.SubmitClaimComponent);
      } else if(this.visualIVRData.module === MODULE.Success) {
        this.router.navigateByUrl(ROUTE.SuccessComponent);
      } else if(this.visualIVRData.module === MODULE.Cancel) {
        if(this.visualIVRData.response == 'LoginTimeout'){
          this.dataService.currentPopupWindow.subscribe(popup => popup.close())
        }
        this.router.navigateByUrl(ROUTE.CancelComponent);
      } else if(this.visualIVRData.module === MODULE.Thankyou) {
        this.router.navigateByUrl(ROUTE.ThankyouComponent);
      }
    });

    


    let id = setInterval(() => {
      //console.log(this.rxStompService.connected())
      if(this.rxStompService.connected()){
        this.notifyLinkOpened()
        this.rxStompService.onSendMessage(this.callDetails, {}, {}, [], {}, 'REGISTER','REGISTER', 'REGISTER', 'REGISTER', this.username, '')
        clearInterval(id)
      }
    }, 200)

  }


  
  notifyLinkOpened() {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Content-Type': 'text',
    })

    const options = {
      headers: httpHeaders,
    };

    this.httpService.post(environment.BASE_URL + APP.OPEN_LINK_URL, {username: this.username, details: this.callDetails}, options).subscribe(
      result => {
        console.log('MainController : Result [' + JSON.stringify(result) + ']')
        // this.router.navigateByUrl(LoaderRoute);
      },
      error => {  
        console.error(error.message || error);
      }
    )

  }

}
