import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ClaimQuestionsModel } from 'src/app/model/claim-questions-model';
import { MODULE } from 'src/app/constants/application-constants';

@Component({
  selector: 'app-submit-claim',
  templateUrl: './submit-claim.component.html',
  styleUrls: ['./submit-claim.component.css']
})
export class SubmitClaimComponent implements OnInit {

  username:string;
  details: any;
  module = MODULE.SubmitClaim

  visualIVRData: any;
  ErrorIAgree:boolean = false
  claimQuesObj:ClaimQuestionsModel

  constructor(
    private rxStompService: RxStompService,
    private dataService:SharedDataService,
    public spinnerService:NgxSpinnerService,
    private modalService:DialogBoxService
    ) {
  }

  ngOnInit(): void {
    this.spinnerService.hide();
    console.log('SubmitClaim Initialized')
    
    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'SubmitClaim','startSubmitClaim', 'startSubmitClaim', 'SUCCESS', this.username, '');
  }

  onClaimSubmit(submitClaimForm:any){

    this.spinnerService.show();
    if(submitClaimForm.value.agreement1 && submitClaimForm.value.agreement2 && submitClaimForm.value.agreement3){
      this.ErrorIAgree = false
      this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'SubmitClaim','finishSubmitClaim', 'finishSubmitClaim', 'CONTINUE', this.username, '');
    }else{
      this.spinnerService.hide();
      this.ErrorIAgree = true
      this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'SubmitClaim','finishSubmitClaim', 'finishSubmitClaim', 'ErrorIAgree', this.username, ''); 
    }

  }

  gotoIncome(){
    this.spinnerService.show();
    this.dataService.currentClaimQuesObject.subscribe(claimQuesObj => this.claimQuesObj= claimQuesObj)
    if(this.claimQuesObj.seekingWork == 'Y'){
      this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'SubmitClaim','backToIncome', 'backToIncome', 'GOTOWORKSEARCH', this.username, '');
    }
    else{
      this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'SubmitClaim','backToIncome', 'backToIncome', 'BACK', this.username, '');
    }
  }

  endCall(){
    // this.spinnerService.show();
    // this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'SubmitClaim','CallEnd', 'CallEnd', 'CallEnd', this.username, ''); 
    this.modalService.open(ConfirmDialogComponent, 'SubmitClaim', this.details, this.username)
  }

}
