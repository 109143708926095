import { Component, OnInit } from '@angular/core';
import { NgbAlertModule, NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClaimQuestionsModel } from 'src/app/model/claim-questions-model';
import { Income } from 'src/app/model/income';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ValidateService } from 'src/app/services/validate.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MODULE } from 'src/app/constants/application-constants';

@Component({
  selector: 'app-claim-questions',
  templateUrl: './claim-questions.component.html',
  styleUrls: ['./claim-questions.component.css']
})
export class ClaimQuestionsComponent implements OnInit {

  username:string;
  details: any;
  module = MODULE.ClaimQuestions

  visualIVRData: any;

  claimQuesObj:ClaimQuestionsModel;
  incomeObj:Income;

  ErrorQuestions:boolean = false;
  emptySeekingWorkDate:boolean = false;
  emptyReturnToWorkDate:boolean = false;

  // availableToWorkQ1:any;
  workSearchdate:NgbDateStruct;
  returntoworkdate:NgbDateStruct;
  
  startDate = moment().startOf('week').subtract(7,'days')
  endDate = moment().endOf('week').subtract(7, 'days')
  minDate:any = {
    year: Number(this.startDate.format('YYYY')), 
    month: Number(this.startDate.format('M')), 
    day: Number(this.startDate.format('D'))
  }
  maxDate:any = {
    year: Number(this.endDate.format('YYYY')), 
    month: Number(this.endDate.format('M')), 
    day: Number(this.endDate.format('D'))
  }
  
  constructor(
    private dataService: SharedDataService,
    private rxStompService: RxStompService,
    private validate:ValidateService,
    public spinnerService:NgxSpinnerService,
    private modalService:DialogBoxService
    ) { }


  // onWorkSearchBtnChange(){
  //   // console.log(e)
  //   if(this.claimQuesObj.seekingWork == 'N'){
  //     this.claimQuesObj.workSearchdate = ''
  //   }
  // }

  onReturnToWorkBtnChange(){
    // console.log(e)
    if(this.claimQuesObj.returnToWork == 'N'){
      this.claimQuesObj.returntoworkdate = ''
    }
  }

  ngOnInit(): void {
    console.log('ClaimQuestions Initialized')
    this.spinnerService.hide();

    this.dataService.currentClaimQuesObject.subscribe(claimQuesObj => this.claimQuesObj = claimQuesObj)
    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'ClaimQuestions','startClaimQuestions', 'startClaimQuestions', 'SUCCESS', this.username, '');
  }

  onClaimQuestionsSubmit(claimQuesForm:any){

    console.log(claimQuesForm.value)
    // this.data.updateQuesClaimObj(claimQuesForm.value)
    this.spinnerService.show();

    if(this.validate.validateRadioButtons(claimQuesForm.value.availableToWork) && this.validate.validateRadioButtons(claimQuesForm.value.seekingWork)
        && this.validate.validateRadioButtons(claimQuesForm.value.refuseFail) && this.validate.validateRadioButtons(claimQuesForm.value.schoolTraining)
        && this.validate.validateRadioButtons(claimQuesForm.value.retirementPension)  && this.validate.validateRadioButtons(claimQuesForm.value.returnToWork)){

        this.ErrorQuestions = false
        // if(claimQuesForm.value.seekingWork == 'Y'){
        //   if(claimQuesForm.value.workSearchdate){
        //     this.emptySeekingWorkDate = false
        //   }else{
        //     this.emptySeekingWorkDate = true
        //   }
        // }else{
        //   // this.claimQuesObj.workSearchdate = "";
        //   this.emptySeekingWorkDate = false
        // }

        if(claimQuesForm.value.returnToWork == 'Y'){
          if(claimQuesForm.value.returntoworkdate){
            this.emptyReturnToWorkDate = false
          }else{
            this.emptyReturnToWorkDate = true
          }
        }else{
          // this.claimQuesObj.returntoworkdate = "";
          this.emptyReturnToWorkDate = false
        }

        if(this.emptyReturnToWorkDate || this.emptySeekingWorkDate){
          this.spinnerService.hide();
          this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'ClaimQuestions','finishClaimQuestions', 'finishClaimQuestions', 'ErrorQuestions', this.username, '');
        }else{
          if(claimQuesForm.value.seekingWork == 'Y'){
            this.rxStompService.onSendMessage(this.details, {}, this.claimQuesObj, [], {}, 'ClaimQuestions','finishClaimQuestions', 'finishClaimQuestions', 'GOTOWORKSEARCH', this.username, '');
          }
          else{  
            this.rxStompService.onSendMessage(this.details, {}, this.claimQuesObj, [], {}, 'ClaimQuestions','finishClaimQuestions', 'finishClaimQuestions', 'CONTINUE', this.username, '');
          }
        }

    }else{
      this.spinnerService.hide();
      this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'ClaimQuestions','finishClaimQuestions', 'finishClaimQuestions', 'ErrorQuestions', this.username, '');
        
        this.ErrorQuestions = true
    }

  }

  goBackToIncome(){
    this.spinnerService.show();
    this.dataService.currentIncomeDetailsObject.subscribe(incomeObj => this.incomeObj = incomeObj)
    if(this.incomeObj.totalEarnings == '0'){
      this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'ClaimQuestions','backToIncome', 'backToIncome', 'SKIPEMPLOYERINFO', this.username, '');
    }
    else{
      this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'ClaimQuestions','backToIncome', 'backToIncome', 'BACK', this.username, '');
    }
  }

  endCall(){
    // this.spinnerService.show();
    // this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'ClaimQuestions', 'TRANSFER', 'TRANSFER', 'TRANSFER', this.username, '')
    this.modalService.open(ConfirmDialogComponent, 'ClaimQuestions', this.details, this.username)
  }

}
