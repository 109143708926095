import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.css']
})
export class BlankComponent implements OnInit {

  code:string;
  state:string;
  constructor(private dataService: SharedDataService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.code  = params['code']
      this.state = params['state']
    });

    if(this.code != '' && this.code != undefined && this.code != null){
      localStorage.setItem("authCode", this.code)
    }

    var message = this.isResponse(location.hash) ? location.hash : '#' + location.search;

    if (window.parent && window.parent !== window) {
        // if loaded as an iframe during silent refresh
        window.parent.postMessage(message, location.origin);
    } else if (window.opener && window.opener !== window) {
        // if loaded as a popup during initial login
        window.opener.postMessage(message, location.origin);
    } else {
        // last resort for a popup which has been through redirects and can't use window.opener
        localStorage.setItem('auth_hash', message);
        localStorage.removeItem('auth_hash');
    }

  }

  isResponse(str:string) {

    var checks = [/[\?|&|#]code=/, /[\?|&|#]error=/, /[\?|&|#]token=/, /[\?|&|#]id_token=/];

    if (!str) return false;
    for(var i=0; i<checks.length; i++) {
        if (str.match(checks[i])) return true;
    }
    return false;
  }

}
