<div class="container-fluid p-0">

    <div class="d-border-bottom">
        <div class="mx-3 my-2 d-flex justify-content-end" >
            <app-language-dropdown [module]="module"></app-language-dropdown>
        </div>
    </div>
    
    <div class="d-border-bottom">
        <div class="p-3" style="position: relative;">
            <label class="form-label" class="d-header-label m-0">
                <!-- ENTER YOUR INCOME -->
                {{'IncomeHead' | propValue | uppercase}}
            </label>
        </div>
    </div>

    <div class="p-3">
        <form #incomeDetailForm="ngForm" (ngSubmit)="onIncomeSubmit(incomeDetailForm)">
            
            <div class="mt-3">
                <div class="mt-3">

                    <label class="form-label" class="form-label" for="">{{'TotalEarningsLabel' | propValue | titlecase}}</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">$</span>
                        <input type="text" placeholder="" [maxcharlength]="5" class="form-control" name="totalEarnings" 
                        [(ngModel)]="incomeObj.totalEarnings" aria-describedby="basic-addon1" autocomplete="nofill" (paste)="onPaste($event);" numeric>
                    </div>
                </div>

                <div class="mt-3">

                    <label class="form-label" for="">{{'RetirementIncomeLabel' | propValue | titlecase}}</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">$</span>
                        <input type="text" placeholder="" [maxcharlength]="5" class="form-control" name="retirementIncome" 
                        [(ngModel)]="incomeObj.retirementIncome" aria-describedby="basic-addon1" autocomplete="nofill" (paste)="onPaste($event);" numeric>
                    </div>

                </div>

                <div class="mt-3">

                    <label class="form-label" for="">{{'SeveranceIncomeLabel' | propValue | titlecase}}</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">$</span>
                        <input type="text" placeholder="" [maxcharlength]="5" class="form-control" name="severanceIncome" 
                        [(ngModel)]="incomeObj.severanceIncome" aria-describedby="basic-addon1" autocomplete="nofill" (paste)="onPaste($event);" numeric>
                    </div>

                </div>

                <div class="mt-3">

                    <label class="form-label" for="">{{'OtherIncomeLabel' | propValue | titlecase}}</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">$</span>
                        <input type="text" placeholder="" [maxcharlength]="5" class="form-control" name="otherIncome" 
                        [(ngModel)]="incomeObj.otherIncome" aria-describedby="basic-addon1" autocomplete="nofill" (paste)="onPaste($event);" numeric>
                    </div>

                </div>

            </div>

            <div class="red-error mt-4" *ngIf="invalidDollar">
                {{'ErrorDollar' | propValue }}                
            </div>

            <div class="my-4 d-flex justify-content-between">
                <button type="submit" class="btn btn-continue">
                    {{'btnContinue' | propValue | uppercase}}
                </button>
                <button type="button" class="btn btn-back" (click)="gotoIncome()">
                    {{'btnBack' | propValue | uppercase}}
                </button>
                <button type="button" class="btn btn-cancel" (click)="endCall()">
                    {{'btnCancel' | propValue | uppercase}}
                </button>
            </div>

        </form>
    </div>
</div>