import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  constructor() { }

  validateDollars(event:any){
    // console.log(event)
    // console.log(event.target.value)
    let excludedKeys = [8, 37, 39, 46, 9, 13, 110, 190];
    
    const keyCode = event.keyCode;
    if(!((keyCode>=48 && keyCode<=57) || (keyCode >= 96 && keyCode <= 105) || (excludedKeys.includes(keyCode)))){
      event.preventDefault()
    }else{
      if(((keyCode>=48 && keyCode<=57) || keyCode==190) && event.shiftKey){
        // console.log('special')
        event.preventDefault()
      }
      if(event.target.value.toString() == '' && event.key == '.'){
        event.target.value = '0'
      }
      if(event.target.value.toString().includes('.')){
        if(event.key == '.')
          event.preventDefault()
      }else{
        if(event.target.value.toString() == '0'){
          if(event.key != '.'){
            event.target.value = ''
  
          }
        }
      }
    }
  }

  validateDollar(dollar:string){
    if(dollar == '' || dollar.includes('.')){
          return false;
    }
    return true
  }

  validateZipCode(event:any){
    // console.log(event)
    let excludedKeys = [8, 37, 39, 46, 9, 13];
    
    const keyCode = event.keyCode;
    if(!((keyCode>=48 && keyCode<=57) || (keyCode >= 96 && keyCode <= 105) || (excludedKeys.includes(keyCode)))){
      event.preventDefault()
    }else{
      if(((keyCode>=48 && keyCode<=57) || keyCode==190) && event.shiftKey){
        // console.log('special')
        event.preventDefault()
      }
      
      if(event.key != 'Backspace' && event.target.value.length == 5){
        event.target.value = event.target.value + '-'
      }
      if(event.key == 'Backspace' && event.target.value.length == 7){
        event.target.value = event.target.value.replace('-','')
      }
    }
  }

  validateRadioButtons(value:string){
    if(value == '' || value == undefined){
          return false;
    }
    return true
  }
}
