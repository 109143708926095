import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BtnUtilService } from 'src/app/services/btn-util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import * as moment from 'moment';
import { MODULE } from 'src/app/constants/application-constants';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  date: any = {};
  sundayMonth: string;
  sundayDay: string;
  sundayYear: string;
  saturdayMonth: string;
  saturdayDay: string;
  saturdayYear: string;
  
  username:string;
  details: any;
  module =  MODULE.Start

  visualIVRData: any;
  btnDesable = true;

  constructor(
    private dataService: SharedDataService,
    private rxStompService: RxStompService,
    public spinnerService:NgxSpinnerService,
    private btnService:BtnUtilService,
    public modalService:DialogBoxService
    ){ 
      
    }

  ngOnInit(): void {    
    this.spinnerService.hide();
    console.log('Start Initialized')
    this.btnService.desable();
    this.dataService.currentUIDates.subscribe(date => this.date = date);
    this.btnService.desabled.subscribe(btnDesable => this.btnDesable = btnDesable);

    localStorage.setItem('startPromptInitTime', moment().format())

    if(this.date){
      this.sundayMonth = this.date.sunday.split(' ')[0]
      this.sundayDay = this.date.sunday.split(' ')[1]
      this.sundayYear = this.date.sunday.split(' ')[2]

      this.saturdayMonth = this.date.saturday.split(' ')[0]
      this.saturdayDay = this.date.saturday.split(' ')[1]
      this.saturdayYear = this.date.saturday.split(' ')[2]
    }
    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'Start','startStart', 'startStart', 'SUCCESS', this.username, '');
  }

  gotoIncome(){
    this.spinnerService.show();
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {}, 'Start', 'finishStart', 'finishStart', 'CONTINUE', this.username, '')
  }

  gotoLogin(){
    this.spinnerService.show();
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {}, 'Start', 'backToLogin', 'backToLogin', 'BACK', this.username, '')
  }

  endCall(){
    this.modalService.open(ConfirmDialogComponent, 'Start', this.details, this.username)
    // this.spinnerService.show();
    // this.rxStompService.onSendMessage(this.details, {}, {}, [], {}, 'Start', 'TRANSFER', 'TRANSFER', 'TRANSFER', this.username, '')
  }
}
