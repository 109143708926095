import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimQuestionsComponent } from './components/claim-questions/claim-questions.component'; 
// import { LoaderComponent } from './loader/loader.component';
import { MainComponent } from './components/main/main.component'; 
import { LoginComponent } from './components/login/login.component'; 
import { StartComponent } from './components/start/start.component'; 
import { IncomeComponent } from './components/income/income.component'; 
import { Income1Component } from './components/income1/income1.component';
import { Income2Component } from './components/income2/income2.component';
import { SubmitClaimComponent } from './components/submit-claim/submit-claim.component'; 
import { SuccessComponent } from './components/success/success.component'; 
import { ThankYouComponent } from './components/thank-you/thank-you.component'; 
import { CancelComponent } from './components/cancel/cancel.component';
import { BlankComponent } from './components/blank/blank.component';
import { LinkExpiredComponent } from './components/link-expired/link-expired.component';
import { WorkSearchComponent } from './components/work-search/work-search.component';

const routes: Routes = [
  {path:"DelawareVIVRUI", component:MainComponent},
  {path:"DelawareVIVRUI/link-expired", component:LinkExpiredComponent},
  {path:"DelawareVIVRUI/blank", component:BlankComponent},
  {path:"DelawareVIVRUI/index", component:MainComponent},
  {path:"DelawareVIVRUI/login", component:LoginComponent},  //login/:username
  {path:"DelawareVIVRUI/start", component:StartComponent},
  {path:"DelawareVIVRUI/income", component:IncomeComponent},
  {path:"DelawareVIVRUI/income/enter-income", component:Income1Component},
  {path:"DelawareVIVRUI/income/employer-income", component:Income2Component},
  {path:"DelawareVIVRUI/claim-questions", component:ClaimQuestionsComponent},
  {path:"DelawareVIVRUI/submit-claim", component:SubmitClaimComponent},  
  {path:"DelawareVIVRUI/success", component:SuccessComponent},
  {path:"DelawareVIVRUI/cancel-claim", component:CancelComponent},
  {path:"DelawareVIVRUI/thankyou", component:ThankYouComponent},
  {path:"DelawareVIVRUI/work-search", component:WorkSearchComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
