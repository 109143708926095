import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BtnUtilService {

  desabled = new BehaviorSubject<boolean>(true);

  constructor() { }

  enable(){
    this.desabled.next(false)
  }

  desable(){
    this.desabled.next(true)
  }
}
