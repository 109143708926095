import { Component, Input, Output, OnInit, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'button-yesno',
  templateUrl: './button-yesno.component.html',
  styleUrls: ['./button-yesno.component.css'],
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => ButtonYesnoComponent),
       multi: true
    }
  ]
})
export class ButtonYesnoComponent implements OnInit, ControlValueAccessor {

@Input() btnGroupName:string;
@Output('btnChange') change = new EventEmitter<string>();

  // btnGroupValue:boolean;

  private _btnGroupValue:string;
  
  constructor() { }

  ngOnInit(): void {
  }

  get btnGroupValue(){
    return this._btnGroupValue;
  }

  set btnGroupValue(value:string){
    
    if(value!=this._btnGroupValue){
      this._btnGroupValue = value;
      this.onChange(value);
    }
  }


  onChange(v:string){

  }

  onBtnValueChange(e:any){
    this.change.emit(e);
  }

  onTouched: any = () => { };

  writeValue(obj: any): void {
    this._btnGroupValue = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
    // throw new Error('Method not implemented.');
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    // throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }


}
