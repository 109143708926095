import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Income } from 'src/app/model/income';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ValidateService } from 'src/app/services/validate.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { MODULE } from 'src/app/constants/application-constants';

@Component({
  selector: 'app-income1',
  templateUrl: './income1.component.html',
  styleUrls: ['./income1.component.css']
})
export class Income1Component implements OnInit {

  username:string;
  details: any;
  module = MODULE.EnterIncome
  
  incomeObj:Income;

  emptyDollarErr:boolean = false;
  invalidDollar:boolean = false;

  constructor(
    private rxStompService: RxStompService,
    private dataService:SharedDataService,
    public validate:ValidateService,
    public spinnerService:NgxSpinnerService,
    private modalService:DialogBoxService
    ) {
  }
  ngOnInit(): void {
    console.log('EnterIncome Initialized')
    this.spinnerService.hide();
   
    this.dataService.currentIncomeDetailsObject.subscribe(incomeObj => this.incomeObj = incomeObj);
    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.details,  {}, {}, [], {}, 'EnterIncome','startEnterIncome', 'startEnterIncome', 'SUCCESS', this.username, '');
  }

  onPaste(e:any){
    console.log('vvb')
    e.preventDefault()

  }

  onIncomeSubmit(incomeDetailForm:any){
    //this.dataService.updateIncomeDetailsObject(incomeDetailForm.value)
    this.spinnerService.show();

    if(this.validate.validateDollar(this.incomeObj.totalEarnings) && this.validate.validateDollar(this.incomeObj.retirementIncome) 
        && this.validate.validateDollar(this.incomeObj.severanceIncome) && this.validate.validateDollar(this.incomeObj.otherIncome)){
          this.invalidDollar = false
    } else{
          this.invalidDollar = true
    }

    if(this.invalidDollar || this.invalidDollar){
      this.spinnerService.hide();
      this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EnterIncome','finishEnterIncome', 'finishEnterIncome', 'ErrorDollar', this.username, '');
    }else{

      if(incomeDetailForm.value.totalEarnings <= 0){
        this.rxStompService.onSendMessage(this.details, this.incomeObj, {}, [], {}, 'EnterIncome','finishEnterIncome', 'finishEnterIncome', 'SKIPEMPLOYERINFO', this.username, '');
      }
      else{  
        this.rxStompService.onSendMessage(this.details, this.incomeObj, {}, [], {}, 'EnterIncome','finishEnterIncome', 'finishEnterIncome', 'CONTINUE', this.username, '');
      }
    }

  }
  
  gotoIncome(){
    this.spinnerService.show();
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EnterIncome','backToIncomeDirections', 'backToIncomeDirections', 'BACK', this.username, '');
  }

  endCall(){
    this.modalService.open(ConfirmDialogComponent, 'EnterIncome', this.details, this.username)  
  }

}

