<div class="my-4 d-flex justify-content-between">
    <button type="button" class="btn btn-continue" (click)="gotoNext()">
        {{'btnContinue' | propValue | uppercase}}
    </button>
    <button type="button" class="btn btn-back" (click)="gotoBack()">
        {{'btnBack' | propValue | uppercase}}
    </button>
    <button type="button" class="btn btn-cancel" (click)="endCall()">
        {{'btnCancel' | propValue | uppercase}}
    </button>
</div>