import { RxStompService } from './rx-stomp.service';
import { myRxStompConfig } from '../stomp/rx-stomp.config';

export function rxStompServiceFactory() {
  const rxStomp = new RxStompService();
  rxStomp.configure(myRxStompConfig);
  rxStomp.activate();
  // rxStomp.stompClient.connectionTimeout = 1000
  return rxStomp;
}
