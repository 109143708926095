import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

  //local
/*
  loginUrl: 'https://api.id.me/oauth/authorize',
  clientId: '00509f786da015537a91d12ee13c5258',
  responseType: 'code',
  scope: 'government', // Add any additional scopes required
  redirectUri: window.location.origin + '/DelawareVIVRUI/blank',
  tokenEndpoint: 'https://api.id.me/oauth/token/',
  // userinfoEndpoint: 'https://api.id.me/api/public/v3/attributes.json',
  showDebugInformation: true, // Set to false in production
*/
  
  //Staging
 /* 
  loginUrl: 'https://api.idmelabs.com/oauth/authorize',
  clientId: '983381a26d1fc8f1523a3b8e641f610a',
  responseType: 'code',
  scope: 'openid http://idmanagement.gov/ns/assurance/ial/2/aal/2', // Add any additional scopes required
  redirectUri: window.location.origin + '/DelawareVIVRUI/blank',
  tokenEndpoint: 'https://api.idmelabs.com/oauth/token/',
  // userinfoEndpoint: 'https://api.id.me/api/public/v3/attributes.json',
  showDebugInformation: true, // Set to false in production
  */
  
  //Production
  
  loginUrl: 'https://api.id.me/oauth/authorize',
  clientId: 'f776847e2d649066fcd01506ec27c0c1',
  responseType: 'code',
  scope: 'openid http://idmanagement.gov/ns/assurance/ial/2/aal/2', // Add any additional scopes required
  redirectUri: window.location.origin + '/DelawareVIVRUI/blank',
  tokenEndpoint: 'https://api.id.me/oauth/token',
  // userinfoEndpoint: 'https://api.id.me/api/public/v3/attributes.json',
  showDebugInformation: true, // Set to false in production
  
  
};
