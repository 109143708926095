<div class="container-fluid p-0">

    <div class="d-border-bottom">
        <div class="mx-3 my-2 d-flex justify-content-end" >
            <app-language-dropdown [module]="module"></app-language-dropdown>
        </div>
    </div>
    
    <div class="d-border-bottom">
        <div class="p-3" style="position: relative;">
            <label class="d-header-label m-0">
                <!-- SUBMIT CLAIM -->
                {{'SubmitClaimHead' | propValue | uppercase}}
            </label>
        </div>
    </div>

    <div class="p-3">
        <form #submitClaimForm="ngForm" (ngSubmit)="onClaimSubmit(submitClaimForm)">
            <div class="mt-3">
                {{'SubmitClaimPara1' | propValue }}
            </div>
            <div class="mt-3">
                <div class="d-grid">
                    <input type="checkbox" class="btn-check" name="agreement1" id="agreement1" autocomplete="off" [ngModel]>
                    <label class="btn btn-block btn-secondary btn-wht" for="agreement1">{{'btnAgree' | propValue | uppercase }}</label>
                </div>
            </div>
            <div class="mt-3">
                {{'SubmitClaimPara2' | propValue }}
            </div>
            <div class="mt-3">
                <div class="d-grid">
                    <input type="checkbox" class="btn-check" name="agreement2" id="agreement2" autocomplete="off" #agreement2 [ngModel]>
                    <label class="btn btn-block btn-secondary btn-wht" for="agreement2">{{'btnAgree' | propValue | uppercase }}</label>
                </div>
            </div>
            <div class="mt-3">
                {{'SubmitClaimPara3' | propValue }}
            </div>
            <div class="mt-3">
                <div class="d-grid">
                    <input type="checkbox" class="btn-check" name="agreement3" id="agreement3" autocomplete="off" #agreement3 [ngModel]>
                    <label class="btn btn-block btn-secondary btn-wht" for="agreement3">{{'btnAgree' | propValue | uppercase }}</label>
                </div>
            </div>

            <div class="mt-3 red-error" *ngIf="ErrorIAgree">
                {{'ErrorIAgree' | propValue }}
            </div>
            
            <div class="my-4 d-flex justify-content-between">
                <button type="submit" class="btn btn-continue">
                    {{'btnSubmit' | propValue | uppercase}}
                </button>
                <button type="button" class="btn btn-back" (click)="gotoIncome()">
                    {{'btnBack' | propValue | uppercase}}
                </button>
                <button type="button" class="btn btn-cancel" (click)="endCall()">
                    {{'btnCancel' | propValue | uppercase}}
                </button>
            </div>
        </form>
    </div>
</div>