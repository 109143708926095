<div class="modal-header p-2 px-3">
    <h5 class="modal-title" id="modal-title">{{'confirmHead' | propValue}}</h5>
</div>
<div class="modal-body text-center">
    <p class="my-1">
        <strong>{{ 'confirmMsg' | propValue }}</strong>
    </p>
</div>
<div class="modal-footer justify-content-center p-2">
    <button type="button" class="btn btn-back col-3" (click)="modal.dismiss('cancel click')"> 
        {{'btnClose' | propValue | uppercase}}
    </button>
    <button type="button" class="btn btn-continue col-3" ngbAutofocus (click)="modal.close('yes')">
        {{'btnYes' | propValue | uppercase}}
    </button>
</div>