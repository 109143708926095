<div class="container-fluid p-0">

    <div class="d-border-bottom">
        <div class="mx-3 my-2 d-flex justify-content-end" >
            <app-language-dropdown [module]="module"></app-language-dropdown>
        </div>
    </div>
    
    <div class="d-border-bottom">
        <div class="p-3" style="position: relative;">
            <label class="form-label" class="d-header-label m-0">
                <!-- ENTER YOUR INCOME -->
                {{'IncomeHead' | propValue | uppercase}}
            </label>
        </div>
    </div>

    <div class="p-3">
        <form #employerDetailForm="ngForm" (ngSubmit)="onEmployerDetailsSubmit(employerDetailForm)">
            
            <ng-container #employerConatainer>
    
            </ng-container>
            
            <div class="mt-3 d-grid">
                
                <button type="button" class="btn btn-continue" (click)="onAddEmployer()">
                    {{'btnAddEmployer' | propValue | uppercase}}
                </button>
    
            </div>
                
            <!-- <button-group moduleName="income"></button-group> -->
            <div class="my-4 d-flex justify-content-between">
                <button type="submit" class="btn btn-continue">
                    {{'btnContinue' | propValue | uppercase}}
                </button>
                <button type="button" class="btn btn-back" (click)="goBackToEnterIncome()">
                    {{'btnBack' | propValue | uppercase}}
                </button>
                <button type="button" class="btn btn-cancel" (click)="endCall()">
                    {{'btnCancel' | propValue | uppercase}}
                </button>
            </div>
        </form>


    </div>
</div>