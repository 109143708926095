import { environment } from "src/environments/environment"

export const APP = {
    OPEN_LINK_URL : '/link-open'
}

export const ROUTE = {

    LoginComponent : `${environment.CONTEXT_PATH}/login`, 			
    StartComponent : `${environment.CONTEXT_PATH}/start`, 			
    IncomeComponent : `${environment.CONTEXT_PATH}/income`, 		
    EnterIncomeComponent : `${environment.CONTEXT_PATH}/income/enter-income`, 		
    EmployerInfoComponent : `${environment.CONTEXT_PATH}/income/employer-income`, 	
    ClaimQuestionsComponent : `${environment.CONTEXT_PATH}/claim-questions`, 	
    SubmitClaimComponent :  `${environment.CONTEXT_PATH}/submit-claim`, 		
    SuccessComponent : `${environment.CONTEXT_PATH}/success`,
    CancelComponent : `${environment.CONTEXT_PATH}/cancel-claim`,
    ThankyouComponent : `${environment.CONTEXT_PATH}/thankyou`,
    LinkExpiredComponent : `${environment.CONTEXT_PATH}/link-expired`, 
    WorkSearchComponent : `${environment.CONTEXT_PATH}/work-search`,
}


export const STOMP = {

    SOCKET_URL : "/chat",
    CHAT_TOPIC : "/user/queue/msg",
    CHAT_BROKER : "/application/chat",
    
}

export const MODULE = {

    Login: 'Login', 			
    Start: 'Start', 			
    IncomeDirections: 'IncomeDirections', 		
    EnterIncome: 'EnterIncome', 		
    EmployerInfo: 'EmployerInfo', 	
    ClaimQuestions: 'ClaimQuestions', 	
    SubmitClaim: 'SubmitClaim', 		
    Success: 'SuccessClaim',
    Cancel: 'CancelClaim',
    Thankyou: 'ThankYou',
    LinkExpired: 'LinkExpired', 
    WorkSearch: 'WorkSearch',
    
}

