import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ClaimQuestionsComponent } from './components/claim-questions/claim-questions.component';
import { SubmitClaimComponent } from './components/submit-claim/submit-claim.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { SuccessComponent } from './components/success/success.component';
import { IncomeComponent } from './components/income/income.component';
import { MainComponent } from './components/main/main.component';
import { StartComponent } from './components/start/start.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ButtonGroupComponent } from './ui/components/button-group/button-group.component';
import { PropValuePipe } from './pipes/prop-value.pipe';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonYesnoComponent } from './ui/components/button-yesno/button-yesno.component';
import { ButtonBlockComponent } from './ui/components/button-block/button-block.component';
import { Income2Component } from './components/income2/income2.component';
import { HttpClientModule } from '@angular/common/http';
import { Income1Component } from './components/income1/income1.component';
import { RxStompService } from './stomp/rx-stomp.service';
import { rxStompServiceFactory } from './stomp/stomp-service-factory';
import { CancelComponent } from './components/cancel/cancel.component';
import { EmployerInfoComponent } from './components/employer-info/employer-info.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OAuthModule } from 'angular-oauth2-oidc';
import { BlankComponent } from './components/blank/blank.component';
import { LinkExpiredComponent } from './components/link-expired/link-expired.component';
import { NumericDirective } from './directives/numeric.directive';
import { MaxcharlengthDirective } from './directives/maxcharlength.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UszipcodeDirective } from './directives/uszipcode.directive';
import { WorkSearchComponent } from './components/work-search/work-search.component';
import { LanguageDropdownComponent } from './components/language-dropdown/language-dropdown.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ClaimQuestionsComponent,
    SubmitClaimComponent,
    ThankYouComponent,
    SuccessComponent,
    IncomeComponent,
    MainComponent,
    StartComponent,
    HeaderComponent,
    FooterComponent,
    ButtonGroupComponent,
    PropValuePipe,
    ButtonYesnoComponent,
    ButtonBlockComponent,
    Income2Component,
    Income1Component,
    CancelComponent,
    EmployerInfoComponent,
    BlankComponent,
    LinkExpiredComponent,
    NumericDirective,
    MaxcharlengthDirective,
    ConfirmDialogComponent,
    UszipcodeDirective,
    WorkSearchComponent,
    LanguageDropdownComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin' }),
    OAuthModule.forRoot(),
    NgbModule
    
  ],
  providers: [ 
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
    },
    NgbActiveModal
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
