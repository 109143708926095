export class WorkSearchModel {

    public employerName:string = '';
    public employerAddress:string = '';
    public employerCity:string = '';
    public employerState:string = '';
    public employerZip:string = '';
    public webAddress:string = '';
    public workSearchdate:any = '';
    public typeOfWorkSought:string = '';
    public contactResult:any = '';
    public otherDetails:string = '';

}
