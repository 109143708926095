import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { WorkSearchModel } from 'src/app/model/work-search-model';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ValidateService } from 'src/app/services/validate.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { MODULE } from 'src/app/constants/application-constants';

@Component({
  selector: 'app-work-search',
  templateUrl: './work-search.component.html',
  styleUrls: ['./work-search.component.css']
})
export class WorkSearchComponent implements OnInit, AfterViewInit {

  username:string;
  details: any;
  module =  MODULE.WorkSearch
  
  workSearchObj:WorkSearchModel;

  invalidName:boolean = false;
  emptyName:boolean = false;
  invalidAddress:boolean = false;
  emptyAddress:boolean = false;
  invalidCity:boolean = false;
  emptyCity:boolean = false;
  invalidState:boolean = false;
  emptyState:boolean = false;
  invalidZip:boolean = false;
  emptyZip:boolean = false;
  emptySeekingWorkDate:boolean = false;
  invalidTypeOfWorkSearch:boolean = false;
  emptyTypeOfWorkSearch:boolean = false;
  invalidContactResult:boolean = false;
  emptyContactResult:boolean = false;
  emptyOther:boolean = false;
  invalidForm:boolean = false;
  event:Event

  workSearchdate:NgbDateStruct;
  
  startDate = moment().startOf('week').subtract(7,'days')
  endDate = moment().endOf('week').subtract(7, 'days')
  minDate:any = {
    year: Number(this.startDate.format('YYYY')), 
    month: Number(this.startDate.format('M')), 
    day: Number(this.startDate.format('D'))
  }
  maxDate:any = {
    year: Number(this.endDate.format('YYYY')), 
    month: Number(this.endDate.format('M')), 
    day: Number(this.endDate.format('D'))
  }

  WSOPTIONS = [
                { key: "FiledApplication", value:"Filed Application" },
                { key: "FiledResume", value:"Filed Resume" },
                { key: "ISAI", value:"Interview Scheduled/Attended Interview"}, 
                { key: "ENHATT", value:"Employer Not Hiring At This Time"}, 
                { key: "Hired", value:"Hired"}, 
                { key: "Other", value:"Other"}
              ]
  // contactResult:Array<string> = WSOPTIONS;

  constructor(
    private rxStompService: RxStompService,
    private dataService:SharedDataService,
    public validate:ValidateService,
    public spinnerService:NgxSpinnerService,
    private modalService:DialogBoxService
    ) {
  }

  onSelect(event:any){
    if(this.workSearchObj.contactResult != 'Other' && this.workSearchObj.contactResult != 'Otro' && this.workSearchObj.contactResult != 'Lòt'){
      this.workSearchObj.otherDetails = '';
    }
  }

  ngAfterViewInit(): void {
    if(this.workSearchObj.contactResult == '' || this.workSearchObj.contactResult == undefined || this.workSearchObj.contactResult == 'Contact Result'){
      this.workSearchObj.contactResult = '';
    }else{
      this.workSearchObj.contactResult = this.workSearchObj.contactResult;
    }
  }
  ngOnInit(): void {
    console.log('WorkSearch Initialized')
    this.spinnerService.hide();
   
    this.dataService.currentWorkSearchObject.subscribe(workSearchObj => this.workSearchObj = workSearchObj);
    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.details,  {}, {}, [], {}, 'WorkSearch','startWorkSearch', 'startWorkSearch', 'SUCCESS', this.username, '');
  }

  onPaste(e:any){
    console.log('vvb')
    e.preventDefault()

  }

  onWorkSearchDetailSubmit(workSearchDetailForm:any){
   //this.dataService.updateWorkSearchObject(workSearchDetailForm.value)
    this.spinnerService.show();

    if(workSearchDetailForm.value.employerName == '' || workSearchDetailForm.value.employerName == undefined){
      this.emptyName = true
    } else{
      this.emptyName = false
    }

    if(workSearchDetailForm.value.employerAddress == '' || workSearchDetailForm.value.employerAddress == undefined){
      this.emptyAddress = true
    } else{
      this.emptyAddress = false
    }

    if(workSearchDetailForm.value.employerCity == '' || workSearchDetailForm.value.employerCity == undefined){
      this.emptyCity = true
    } else{
      this.emptyCity = false
    }

    // if(workSearchDetailForm.value.employerZip == '' || workSearchDetailForm.value.employerZip == undefined){
    //   this.emptyZip = true
    // } else{
    //   this.emptyZip = false
    // }

    if(this.workSearchObj.employerZip.length != 0 && this.workSearchObj.employerZip.length != 5 && this.workSearchObj.employerZip.length != 10){
      this.invalidZip = true
    } else{
      this.invalidZip = false
    }

    if(workSearchDetailForm.value.employerState == '' || workSearchDetailForm.value.employerState == undefined){
      this.emptyState = true
    } else{
      this.emptyState = false
    }

    if(workSearchDetailForm.value.typeOfWorkSought == '' || workSearchDetailForm.value.typeOfWorkSought == undefined){
      this.emptyTypeOfWorkSearch = true
    } else{
      this.emptyTypeOfWorkSearch = false
    }

    if(workSearchDetailForm.value.contactResult == '' || workSearchDetailForm.value.contactResult == undefined || workSearchDetailForm.value.contactResult == 'Contact Result'){
      this.emptyContactResult = true
    } else{
      this.emptyContactResult = false
    }

    if(workSearchDetailForm.value.workSearchdate){
      this.emptySeekingWorkDate = false
    }else{
      this.emptySeekingWorkDate = true
    }

    if(this.emptyName || this.emptyAddress || this.emptyCity ||  this.invalidZip || this.emptyState || this.emptyTypeOfWorkSearch || this.emptyContactResult || this.emptySeekingWorkDate){
      this.invalidForm = true
    }
    else{
      this.invalidForm = false
    }


    if(this.invalidForm){

      this.spinnerService.hide();
      if(this.invalidName || this.emptyName){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'WorkSearch','finishWorkSearch', 'finishWorkSearch', 'ErrorEmployerName', this.username, '');
      }
      else if(this.invalidAddress || this.emptyAddress){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'WorkSearch','finishWorkSearch', 'finishWorkSearch', 'ErrorAddress', this.username, '');
      }
      else if(this.invalidCity || this.emptyCity){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'WorkSearch','finishWorkSearch', 'finishWorkSearch', 'ErrorCity', this.username, '');
      }
      else if(this.invalidState || this.emptyState){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'WorkSearch','finishWorkSearch', 'finishWorkSearch', 'ErrorState', this.username, '');
      }
      else if(this.invalidZip){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'WorkSearch','finishWorkSearch', 'finishWorkSearch', 'ErrorZipCode', this.username, '');
      }
      else if(this.emptySeekingWorkDate){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'WorkSearch','finishWorkSearch', 'finishWorkSearch', 'ErrorDate', this.username, '');
      }
      else if(this.invalidTypeOfWorkSearch || this.emptyTypeOfWorkSearch){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'WorkSearch','finishWorkSearch', 'finishWorkSearch', 'ErrorWorkSought', this.username, '');
      }
      else if(this.invalidContactResult || this.emptyContactResult){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'WorkSearch','finishWorkSearch', 'finishWorkSearch', 'ErrorContactResult', this.username, '');
      }
    }else{
        this.rxStompService.onSendMessage(this.details, {}, {}, [], this.workSearchObj,'WorkSearch','finishWorkSearch', 'finishWorkSearch', 'CONTINUE', this.username, '');
    }
  } 
  
  goBackToClaimQuestions(){
    this.spinnerService.show();
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'WorkSearch','backToClaimQuestions', 'backToClaimQuestions', 'BACK', this.username, '');
  }

  endCall(){
    this.modalService.open(ConfirmDialogComponent, 'WorkSearch', this.details, this.username)  
  }

  ngOnDestroy(){

  }


}
