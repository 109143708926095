import { Injectable } from '@angular/core';
import * as CryptoJs from 'crypto-js'

@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {

  constructor() { }

  encrypt(value:string):string{
    let key1 = CryptoJs.enc.Utf8.parse("AcSvrYiUMtKytiTE");
    let key2 = CryptoJs.enc.Utf8.parse("MheShtiEtkyrYiMU");
    // let encrypted = CryptoJs.AES.encrypt("delawareVIVR", key1, { iv: key2 });
    
    let encrypted = CryptoJs.AES.encrypt(value, key1, { iv: key2 });
    console.log("encrypted: <'"+encrypted.toString()+"'>")
    return encrypted.toString();
  }

  decrypt(encrypted:string):string{
    let key1 = CryptoJs.enc.Utf8.parse("AcSvrYiUMtKytiTE");
    let key2 = CryptoJs.enc.Utf8.parse("MheShtiEtkyrYiMU");
    let decrypted = CryptoJs.AES.decrypt(encrypted, key1, { iv: key2 });
    console.log("decrypted: <'"+decrypted.toString(CryptoJs.enc.Utf8)+"'>")
    return decrypted.toString(CryptoJs.enc.Utf8);
  }

}
