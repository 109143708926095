import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BtnUtilService } from 'src/app/services/btn-util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import * as moment from 'moment';
import { MODULE } from 'src/app/constants/application-constants';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css']
})
export class IncomeComponent implements OnInit {

  date: any = {};
  sundayMonth: string;
  sundayDay: string;
  sundayYear: string;
  saturdayMonth: string;
  saturdayDay: string;
  saturdayYear: string;

  username:string;
  details: any;
  module = MODULE.IncomeDirections

  visualIVRData: any;

  incomeObj:any;
  btnDesable = true;

  constructor(
    private rxStompService: RxStompService,
    private dataService:SharedDataService,
    public spinnerService:NgxSpinnerService,
    private btnService:BtnUtilService,
    private modalService:DialogBoxService
    ) {
  }
  ngOnInit(): void {
    console.log('Income Initialized')
    this.spinnerService.hide();
    this.btnService.desable();
    this.btnService.desabled.subscribe(btnDesable => this.btnDesable = btnDesable);    
    this.dataService.currentUIDates.subscribe(date => this.date = date);

    localStorage.setItem('incomePromptInitTime', moment().format())

    if(this.date){
      this.sundayMonth = this.date.sunday.split(' ')[0]
      this.sundayDay = this.date.sunday.split(' ')[1]
      this.sundayYear = this.date.sunday.split(' ')[2]

      this.saturdayMonth = this.date.saturday.split(' ')[0]
      this.saturdayDay = this.date.saturday.split(' ')[1]
      this.saturdayYear = this.date.saturday.split(' ')[2]
    }
    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {}, 'IncomeDirections', 'startIncomeDirections', 'startIncomeDirections', 'SUCCESS', this.username, '');
  }

  onIncomeSubmit(){
    this.spinnerService.show();
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {}, 'IncomeDirections', 'finishIncomeDirections', 'finishIncomeDirections', 'CONTINUE', this.username, '')
  }
  
  gotoStart(){
    this.spinnerService.show();
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {}, 'IncomeDirections', 'backToStart', 'backToStart', 'BACK', this.username, '')
  }

  endCall(){
    this.modalService.open(ConfirmDialogComponent, 'IncomeDirections', this.details, this.username)
  }

}
