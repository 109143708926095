import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent implements OnInit {

  constructor(
    public spinnerService:NgxSpinnerService,
    private rxStompServive:RxStompService
  ) { }

  ngOnInit(): void {
    this.spinnerService.hide();
    this.rxStompServive.deactivate()
  }

}
