import { Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { EmployerInfoComponent } from '../employer-info/employer-info.component';
import { EmployerInfoModel } from 'src/app/model/employer-info-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MODULE } from 'src/app/constants/application-constants';

@Component({
  selector: 'app-income2',
  templateUrl: './income2.component.html',
  styleUrls: ['./income2.component.css']
})
export class Income2Component implements OnInit, OnDestroy {

  @ViewChild('employerConatainer', {static : true, read : ViewContainerRef}) container: ViewContainerRef;

  date: any = {};

  index:number;
  components:Map<number, ComponentRef<any>>;
  formValidationOp: any[] = []

  username:string;
  details: any;
  module = MODULE.EmployerInfo

  visualIVRData: any;
  invalidDollar:boolean = false;
  invalidName:boolean = false;
  emptyName:boolean = false;
  invalidAddress:boolean = false;
  emptyAddress:boolean = false;
  invalidCity:boolean = false;
  emptyCity:boolean = false;
  invalidState:boolean = false;
  emptyState:boolean = false;
  invalidZip:boolean = false;
  emptyZip:boolean = false;

  employerInfoObject:EmployerInfoModel[];

  constructor(
    private rxStompService: RxStompService,
    private dataService:SharedDataService,
    public spinnerService:NgxSpinnerService,
    private modalService:DialogBoxService
    ) {
  }
  ngOnInit(): void {
    console.log('EmployerInfo Initialized')
    this.spinnerService.hide();

    this.dataService.currentEmployerId.subscribe(index => this.index = index);
    this.dataService.currentcallDetails.subscribe(details => this.details = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EmployerInfo','startEmployerInfo', 'startEmployerInfo', 'SUCCESS', this.username, '');
    
    this.preserveEmployers()
    if(this.components.size == 0){
      this.onAddEmployer()
    }
  }

  preserveEmployers() {
    this.dataService.currentEmployerInfoComponents.subscribe(components => this.components = components);
    let i=0;
    this.components.forEach((comp, key) => {
      if(comp.instance.invalidForm){
        comp.instance.invalidDollar = false;
        comp.instance.emptyName = false;
        comp.instance.invalidName = false;
        comp.instance.emptyAddress = false;
        comp.instance.invalidAddress = false;
        comp.instance.emptyCity = false;
        comp.instance.invalidCity = false;
        comp.instance.emptyState = false;
        comp.instance.invalidState = false;
        comp.instance.emptyZip = false;
        comp.instance.invalidZip = false;

        comp.instance.invalidForm = false;
      }
      this.container.insert(comp.hostView, i)
      i++;
    })
  }

  onAddEmployer(){
    
    const component = this.container.createComponent(EmployerInfoComponent);
    component.instance.employerId = this.index;
    
    component.instance.closed.subscribe((res: any) => {
      this.deleteEmployer(res.employerId)
      let i = 1;
      this.components.forEach((val,key)=>{
        val.instance.employerNum = i
        i++;
      })
    })

    // component.instance.isFormValid.subscribe((res: any) => {
    //   console.log(res)
    // })

    this.components.set(this.index, component);

    let i = 1;
    this.components.forEach((val,key)=>{
      val.instance.employerNum = i
      i++;
    })
    this.index++;

  }

  deleteEmployer(employerId:number){
    if(this.components.has(employerId)){
      this.components.get(employerId)?.destroy()
      this.components.delete(employerId)
    }
  }

  onEmployerDetailsSubmit(employerDetailForm:any){

    this.spinnerService.show();
    this.dataService.updateEmployerInfoComponents(this.components)
    this.dataService.updateEmployerId(this.index)

    this.formValidationOp = []
    this.components.forEach((component,key)=>{
      component.instance.triggerSubmit()
      //console.log(component.instance.employerInfoObj)
      if(component.instance.invalidForm){
        this.formValidationOp.push(
          {
          'dollar':component.instance.invalidDollar,
          'name':component.instance.emptyName || component.instance.invalidName,
          'address':component.instance.emptyAddress || component.instance.invalidAddress,
          'city':component.instance.emptyCity || component.instance.invalidCity,
          'state':component.instance.emptyState || component.instance.invalidState,
          'zip':component.instance.emptyZip || component.instance.invalidZip,
          }
          )
      }
    })

    if(this.formValidationOp.length > 0){

      this.spinnerService.hide();
      if(this.formValidationOp[0].dollar){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EmployerInfo','finishEmployerInfo', 'finishEmployerInfo', 'ErrorDollar', this.username, '');
      }
      else if(this.formValidationOp[0].name){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EmployerInfo','finishEmployerInfo', 'finishEmployerInfo', 'ErrorEmployerName', this.username, '');
      }
      else if(this.formValidationOp[0].address){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EmployerInfo','finishEmployerInfo', 'finishEmployerInfo', 'ErrorAddress', this.username, '');
      }
      else if(this.formValidationOp[0].city){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EmployerInfo','finishEmployerInfo', 'finishEmployerInfo', 'ErrorCity', this.username, '');
      }
      else if(this.formValidationOp[0].state){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EmployerInfo','finishEmployerInfo', 'finishEmployerInfo', 'ErrorState', this.username, '');
      }
      else if(this.formValidationOp[0].zip){
        this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EmployerInfo','finishEmployerInfo', 'finishEmployerInfo', 'ErrorZipCode', this.username, '');
      }
    }else{

      const employerInfoArr = Array.from(this.components.values()).map(c => c.instance.employerInfoObj)
      console.log(employerInfoArr)
      
      this.rxStompService.onSendMessage(this.details, {}, {}, employerInfoArr, {}, 'EmployerInfo','finishEmployerInfo', 'finishEmployerInfo', 'CONTINUE', this.username, '');
      while(this.container.length>0){
        this.container.detach();
      }
    }

  }
  
  goBackToEnterIncome(){
    this.spinnerService.show();
    while(this.container.length>0){
      this.container.detach();
    }
    this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EmployerInfo','backToIncome', 'backToIncome', 'BACK', this.username, '');

  }

  endCall(){
    // this.spinnerService.show();
    // this.rxStompService.onSendMessage(this.details, {}, {}, [], {},'EmployerInfo', 'TRANSFER', 'TRANSFER', 'TRANSFER', this.username, '');
    this.modalService.open(ConfirmDialogComponent, 'EmployerInfo', this.details, this.username)
  }

  ngOnDestroy(){

  }

}
