<div class="container-fluid p-0">

    <div class="d-border-bottom">
        <div class="p-3">
            <label class="d-header-label m-0">
                {{'CancelClaimHead' | propValue | uppercase}}
            </label>
        </div>
    </div>

    <p class="p-3 fs-5">
        {{'CancelClaimMsg' | propValue }}
    </p>
</div>