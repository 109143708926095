import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationStringsEnglish, ApplicationStringsHaitianCreole, ApplicationStringsSpanish } from '../constants/application-strings';
import { SharedDataService } from '../services/shared-data.service';

@Pipe({
  name: 'propValue',
  pure: false
})
export class PropValuePipe implements PipeTransform {

  language:string = 'en'
  
  constructor(
     private dataService:SharedDataService
     ){

  }

  value:string = ''
  transform(key: string, args?: any): string {

    this.dataService.currentLanguage.subscribe(language => {
      this.language = language;
    })

    // console.log(this.language)
    if(this.language == '' || this.language == undefined || this.language == null){
      this.language = 'en';
    }
    if(this.language === 'en'){
      if(key in ApplicationStringsEnglish){
        this.value = ApplicationStringsEnglish[key];
      }
      else{
        // console.error(`No property value set for key '${key}'`)
        this.value = key;
      }
    }
    if(this.language === 'es'){
      if(key in ApplicationStringsSpanish){
        this.value = ApplicationStringsSpanish[key];
      }
      else{
        // console.error(`No property value set for key '${key}'`)
        this.value = key;
      }
    }
    if(this.language === 'hc'){
      if(key in ApplicationStringsHaitianCreole){
        this.value = ApplicationStringsHaitianCreole[key];
      }
      else{
        // console.error(`No property value set for key '${key}'`)
        this.value = key;
      }
    }
    return this.value;
  }
}
