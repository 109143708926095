export class EmployerInfoModel {

    public totalEarning:string = '';
    public employerName:string = '';
    public employerAddress:string = '';
    public employerCity:string = '';
    public employerZip:string = '';
    public employerState:string = '';
    
}
