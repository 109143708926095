import { Injectable, OnInit } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { STOMP } from '../constants/application-constants';

@Injectable({
  providedIn: 'root',
})
export class RxStompService extends RxStomp {
  constructor() {
    super();
  }

  ngOnInit(): void {
    // this.watch(STOMP.CHAT_TOPIC).subscribe((message: any) => {
    //   console.log('Module name received as [' + message.body + "]");	
    // });
  }

  onSendMessage(callDetails:any, incomeObj:any, claimQuesObj:any, employerInfoList:any, workSearchObj:any, moduleName:string, moduleMessage:string, uiActivity:string, uiResponse:string, username:string, uuid:string) {
    const message = `Message generated at ${new Date()}`;
    // console.log("sendMessage: ",incomeObj, claimQuesObj, employerInfoList)
    console.log("send:: ", JSON.stringify({
      details: callDetails,
      incomeInfo: incomeObj,
      claimQuestionsInfo: claimQuesObj,
      employerInfoList: employerInfoList,
      workSearchObj : workSearchObj,
      module: moduleName,
      message: moduleMessage,
      activity: uiActivity,
      response: uiResponse,
      username: username,
      uuid: uuid
    }))
    this.publish({ destination: STOMP.CHAT_BROKER, 
      body: JSON.stringify({
        details: callDetails,
        incomeInfo: incomeObj,
        claimQuestionsInfo: claimQuesObj,
        employerInfoList: employerInfoList,
        workSearchObj : workSearchObj,
        module: moduleName,
        message: moduleMessage,
        activity: uiActivity,
        response: uiResponse,
        username: username,
        uuid: uuid
      })
    });
  }

}


