import { ComponentRef, Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { ClaimQuestionsModel } from '../model/claim-questions-model';
import { Income } from '../model/income';
import { WorkSearchModel} from '../model/work-search-model';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private callDetails = new BehaviorSubject<any>({});
  currentcallDetails = this.callDetails.asObservable();
  
  private username = new BehaviorSubject('');
  currentUsername = this.username.asObservable();

  private language = new BehaviorSubject('');
  currentLanguage = this.language.asObservable();

  private uiDates = new BehaviorSubject<any>('');
  currentUIDates = this.uiDates.asObservable();

  private claimQuesObject = new BehaviorSubject(new ClaimQuestionsModel());
  currentClaimQuesObject = this.claimQuesObject.asObservable();

  private incomeDetailsObject = new BehaviorSubject(new Income());
  currentIncomeDetailsObject = this.incomeDetailsObject.asObservable();

  private employerInfoComponents = new BehaviorSubject(new Map<number, ComponentRef<any>>());
  currentEmployerInfoComponents = this.employerInfoComponents.asObservable();
  
  private employerId = new BehaviorSubject(0);
  currentEmployerId = this.employerId.asObservable();

  private popupWindow = new BehaviorSubject<any>(Window);
  currentPopupWindow = this.popupWindow.asObservable();

  private workSearchObject = new BehaviorSubject(new WorkSearchModel());
  currentWorkSearchObject = this.workSearchObject.asObservable();

  constructor() {

  }

  

  updateUsername(username:string){
    this.username.next(username);
  }

  updateLanguage(language:string){
    this.language.next(language);
  }

  updateCallDetails(callDetails:any){
    this.callDetails.next(callDetails);
  }

  updateUIDates(uiDates:any){
    this.uiDates.next(uiDates);
  }

  updateClaimQuesObject(claimQuesObject:ClaimQuestionsModel){
    this.claimQuesObject.next(claimQuesObject);
  }

  updateIncomeDetailsObject(incomeDetailsObject:Income){
    this.incomeDetailsObject.next(incomeDetailsObject);
  }

  updateEmployerInfoComponents(employerInfoComponents:any){
    this.employerInfoComponents.next(employerInfoComponents);
  }

  updateEmployerId(employerId:number){
    this.employerId.next(employerId);
  }

  updatePopupWindow(popupWindow:Window){
    this.popupWindow.next(popupWindow);
  }

  updateWorkSearchObject(workSearchObject:WorkSearchModel){
    this.workSearchObject.next(workSearchObject);
  }

}
