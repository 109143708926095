<div class="container-fluid p-0">

    <div class="d-border-bottom">
        <div class="p-3">
            <label class="d-header-label m-0">
                <!-- SUCCESS -->
                {{'SuccessHead' | propValue | uppercase}}
            </label>
        </div>
    </div>

    <div class="p-3">

        <div class="mt-3">
            {{'SuccessThankuMsg' | propValue }} {{saturdayMonth  | propValue}} {{saturdayDay}} {{saturdayYear}}.
            <br>
            <br>
            {{'SuccessConfMsg' | propValue }}
        </div>
        <!-- <div class="pt-3 pb-4 d-flex justify-content-between">
            <button class="btn btn-continue" (click)="close()">
                {{'btnClose' | propValue | uppercase}}
            </button>
        </div> -->

    </div>
</div>